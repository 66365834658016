import React from 'react'
import travel from "../assets/img/dashboard/travel.webp"
import hotel from "../assets/img/dashboard/hotel.webp"
import money from "../assets/img/dashboard/money.webp"
import funds from "../assets/img/dashboard/funds.webp"
import store from "../assets/img/dashboard/store.webp"
import loading from "../assets/img/dashboard/loading.webp"
import bills from "../assets/img/dashboard/bills.webp"
import reports from "../assets/img/dashboard/reports.webp"

const Home = () => {
  return (
    <div className="bg-[#E7EEF8]">
        <div className="grid grid-cols-3 grid-rows-3 lg:grid-cols-4 lg:grid-rows-2">
          <div className="mt-4">
            <img src={travel} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Travel and Tour</h4>
          </div>
          <div className="mt-4">
            <img src={hotel} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Hotel booking</h4>
          </div>
          <div className="mt-4">
            <img src={money} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Money Transfer</h4>
          </div>
          <div className="mt-4">
            <img src={funds} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Master Funds</h4>
          </div>
          <div className="mt-4">
            <img src={store} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Online Store</h4>
          </div>
          <div className="mt-4">
            <img src={loading} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Loading Business</h4>
          </div>
          <div className="mt-4">
            <img src={bills} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Bills Payment</h4>
          </div>
          <div className="mt-4">
            <img src={reports} alt='' className='lg:w-44 lg:h-44 mx-auto'/>
            <h4 className='text-[14px] lg:text-[20px] text-[#4A4A4F] text-center'>Reports</h4>
          </div>
        </div>
    </div>

  )
}

export default Home