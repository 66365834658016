import React, { useState } from 'react';
import imgOne from '../assets/img/landingpage/imgOne.webp';
import imgTwo from '../assets/img/landingpage/imgTwo.webp';
import imgThree from '../assets/img/landingpage/imgThree.webp';
import imgFour from '../assets/img/landingpage/imgFour.webp';
import logo from '../assets/img/landingpage/logo.webp';
import Carousel from './Carousel';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const SignIn = () => {
  const imageUrls = [imgOne, imgTwo, imgThree, imgFour];
  const [password, setPassword] = useState('');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  return (
    <>
      <div
        className="fixed inset-0 z-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${imageUrls[0]})`, // Assuming you want to use the first image as background
        }}
      ></div>
      <Carousel imageUrls={imageUrls} className="hidden" /> {/* Hide the carousel */}
      <div className="relative p-8 font-dm">
        <a href='/'><img src={logo} alt='' className='w-32'/></a>
        <div className="flex justify-center w-full h-full items-center mt-4">
          <div className="bg-white rounded-lg w-full lg:w-2/5 py-10 px-4 lg:px-20 z-10">
            <h1 className="text-center text-[#333333] text-3xl font-bold mb-6">Login</h1>
            <p className="text-center text-[14px] text-[#666666] mb-4">
            Don’t have an account? <span className="text-[#111111] underline cursor-pointer"><a href='/sign-up'>Sign up</a></span>
            </p>
            <div className="mb-4">
              <h6 className='text-[#666666] text-[14px]'>Username</h6>
              <input type="text" className="border rounded-md px-4 py-2 h-12 w-full" />
            </div>
            <div className="mb-4">
              <div className="flex justify-between">
                <h6 className='text-[#666666] text-[14px]'>Password</h6>
                <span className="flex justify-around items-center text-[#666666] text-[14px]" onClick={handleToggle}>
                  <Icon className="mr-2 text-[#666666]" icon={icon} size={16} />&nbsp;Hide
                </span>
              </div>
              <input
                type={type}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                className="border rounded-md px-4 py-2 h-12 w-full"
              />
              <div className="flex items-center my-4">
                <input type="checkbox" id="rememberMe" className="mr-2" />
                <label htmlFor="rememberMe" className='text-[#666666] text-[14px]'>Remember me</label>
              </div>
              <a href='/home'><button className='bg-[#006FEE] w-full rounded-full py-2 text-white mt-2'>Log in</button></a>
              <h6 className='text-center text-[#111111] text-[14px] mt-4'><a href='/forgot-password'>Forgot password?</a></h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
