import React, { useState, useEffect } from 'react';
import Lottie from "lottie-react";
import oneplace from "../assets/img/landingpage/oneplace.json"
import onestop from "../assets/img/landingpage/onestop.json"


const Hero = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState('left');

    const items = [
      {
        id: 'item1',
        title: "Get It All in One Place",
        description: "Your One-Stop Solution for Mobile Load, Bill Payments, Cash Transfer, Flight and Hotel Bookings",
        button: "Get started",
        animation1: <Lottie animationData={oneplace} />,
      },
      {
        id: 'item2',
        title: "One-Stop Travel and Hotel Booking",
        description: "Book Flights and Hotels Easily and Quickly",
        button: "Get started",
        animation2: <Lottie animationData={onestop} />,
      }
    ];

    useEffect(() => {
      const interval = setInterval(() => {
        setSlideDirection('left');
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, 10000);

      return () => clearInterval(interval);
    }, [items.length]);
    
    console.log(slideDirection);


    return (
      <div className="bg-hero section">
        <div className="px-8 lg:px-40 font-pjs">
          <div className="carousel w-full mt-8 relative overflow-hidden">
            <div className="carousel-inner flex transition-transform duration-1000" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
              {items.map((item, index) => (
                <div key={item.id} className="carousel-item w-full flex flex-col lg:flex-row justify-between flex-shrink-0">
                  <div className="w-full lg:w-1/2 mt-4 lg:mt-60 order-2 lg:order-1">
                    <div className='animate-pop-once'>
                      <div className="text-white text-center lg:text-left">
                        <h1 className="text-[28px] lg:text-[60px] font-bold leading-none my-4">
                          {item.title}
                        </h1>
                        <p className="text-[12px] lg:text-[20px] font-regular my-4">
                          {item.description}
                        </p>
                      </div>
                      <div className="flex justify-center lg:justify-start">
                        <button className="text-[#1F89FA] text-[12px] lg:text-[16px] font-bold bg-white rounded-full py-2 px-4">
                          {item.button}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`w-[100%] ${item.animation1 ? 'lg:w-[35%]' : 'lg:w-[45%]'} mx-auto mt-36 lg:mt-32 order-1 lg:order-2`}>
                    {item.animation1 || item.animation2}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Hero