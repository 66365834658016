import React, { useState } from 'react';
import profile1 from "../assets/img/dashboard/profile1.png"
import cloud from "../assets/img/dashboard/cloud.svg"
import close from "../assets/img/dashboard/close.svg"
import edit from "../assets/img/dashboard/edit.svg"
import { useNavigate } from 'react-router-dom';


const Profile = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };
    const handleFileRemove = () => {
        setSelectedFile(null);
    };
    const handleClick = () => {
        navigate('/profile/bank');
    };
    
    return (
        <div className="bg-[#E7EEF8]">
            <div className="flex flex-col lg:flex-row justify-between gap-4 mb-4">
                <div className="bg-user rounded-2xl text-center p-12 h-1/2">
                    <img
                        src={profile1}
                        alt=""
                        className="w-24 h-24 mx-auto mt-20 mb-4 cursor-pointer"
                        onClick={toggleModal}
                    />
                    {isModalOpen && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="bg-white p-6 rounded-lg">
                                {selectedFile ? (
                                    <>
                                        <div className="bg-[#F1F5F9] rounded-lg mb-4">
                                            <div className="flex justify-end">
                                                <img src={close} alt='' className='cursor-pointer pt-3 pr-3' onClick={toggleModal} />
                                            </div>
                                            <div className="p-8">
                                                <img src={cloud} alt='' className='mx-auto' />
                                                <h2 className="text-[24px] font-semibold mb-4">Uploaded picture</h2>
                                                <p className='text-[#292D32] text-[18px]'><span className='font-bold'>Selected file:</span> <span className='italic'>{selectedFile.name}</span></p>
                                            </div>
                                        </div>
                                        <button className="text-white bg-red-500 rounded-lg px-8 py-2 mt-4" onClick={handleFileRemove}>Remove</button>
                                    </>
                                ) : (
                                    <>
                                        <div className="bg-[#F1F5F9] rounded-lg mb-4">
                                            <div className="flex justify-end">
                                                <img src={close} alt='' className='cursor-pointer pt-3 pr-3' onClick={toggleModal} />
                                            </div>
                                            <div className="p-8">
                                                <img src={cloud} alt='' className='mx-auto' />
                                                <h2 className="text-[24px] font-semibold mb-4">Upload a picture</h2>
                                                <div>
                                                    <p className='text-[#292D32] text-[18px]'>Choose a file or drag & drop it here</p>
                                                    <p className='text-[#A9ACB4] text-[14px]'>JPEG, PNG, PDG, and MP4 formats, up to 50MB</p>
                                                    <input type="file" onChange={handleFileChange} className="hidden" />
                                                </div>
                                            </div>
                                        </div>
                                        <button className="text-white bg-blue-500 rounded-lg px-8 py-2" onClick={() => document.querySelector('input[type="file"]').click()}> Upload </button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <h2 className='text-[20px] text-[#09090B] font-bold'>John Doe</h2>
                    <p className='text-[14px] text-[#71717A] mb-4'>5322785</p>
                    <p className='text-[#2558ED] font-semibold bg-[#D3DDF5] rounded-lg w-1/2 mx-auto mb-8'>Dealer</p>
                    <button className='text-white font-semibold bg-[#006FEE] rounded-lg py-2 px-12'>Edit Profile</button>
                </div>
                <div className="bg-white rounded-2xl p-8 flex-grow">
                    <div className="flex justify-between">
                      <h2 className='text-[20px] text-[#09090B] font-bold'>Profile</h2>
                      <button><img src={edit} alt=''/></button>
                    </div>
                    <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-2'>Full Name</p>
                    <input type="text" placeholder="Full Name" className="input input-sm w-full mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />
                    <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-2'>Mobile No.</p>
                    <input type="number" placeholder="0918*******124" className="input input-sm w-full mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />
                    <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-2'>Email</p>
                    <input type="email" placeholder="Email Address" className="input input-sm w-full mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />
                    <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-2'>Password</p>
                    <input type="password" placeholder="**************" className="input input-sm w-full mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />
                    <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold'>Save</button>
                    <button className='bg-[#CCCCCC] text-white w-full mt-6 py-2 rounded-2xl font-semibold'>Cancel</button>
                </div>
                <div className="bg-white rounded-2xl p-8 flex-grow">
                    <div className="flex justify-between">
                      <h2 className='text-[20px] text-[#09090B] font-bold'>Bank</h2>
                      <button onClick={handleClick}><img src={edit} alt=''/></button>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer gap-4">
                        <div className="bg-[#F1F5F9] p-4 rounded-full text-[#18181B] font-bold flex justify-between w-full">
                          <span className="label-text">BPI</span>
                          <span className="label-text">1234*************#4123</span>
                        </div>
                        <input type="radio" name="radio-10" className="radio checked:bg-[#05CD99]" checked />
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer gap-4">
                        <div className="bg-[#F1F5F9] p-4 rounded-full text-[#18181B] font-bold flex justify-between w-full">
                          <span className="label-text">BPI</span>
                          <span className="label-text">1234*************#4123</span>
                        </div>
                        <input type="radio" name="radio-10" className="radio checked:bg-[#05CD99]" checked />
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer gap-4">
                        <div className="bg-[#F1F5F9] p-4 rounded-full text-[#18181B] font-bold flex justify-between w-full">
                          <span className="label-text">BPI</span>
                          <span className="label-text">1234*************#4123</span>
                        </div>
                        <input type="radio" name="radio-10" className="radio checked:bg-[#05CD99]" checked />
                      </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
