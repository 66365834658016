import React from 'react'
import banner from '../assets/img/landingpage/banner.webp'

const GettingStarted = () => {
  return (
    <div id="contact" className='px-4 mt-4 font-pjs section' style={{ animationDelay: '3.5s' }}>
        <div className='absolute hidden md:block'>
            <img src={banner} alt='banner' className='w-[80%] h-auto mx-auto'/>
        </div>
        <div className='relative'>
            <div className="w-full md:w-1/2 p-4 lg:pl-48 animate-pop-once">
                <h1 className='text-[#353F4F] md:text-white text-[28px] lg:text-[40px] text-center md:text-left font-bold tracking-wide mt-4 lg:mt-32 mb-2'>Getting started with NetClixs today!</h1>
                <p className='text-[#353F4F] md:text-white text-[18px] text-center md:text-left mb-8'>Enjoy discounts, rewards, and real-time updates! Sign up today and elevate your everyday experience!</p>
                <div className="flex justify-center md:justify-start items-center mt-6">
                    <button className='text-[#1F89FA] text-[12px] lg:text-[16px] font-bold bg-white hover:bg-[#1fa6fa] hover:text-white border-2 border-white rounded-full py-2 px-4'>Get started - it's free</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GettingStarted