import React from 'react'
import logoblack from '../assets/img/landingpage/logoblack.webp'
import fb from '../assets/img/landingpage/fb.png'
import tw from '../assets/img/landingpage/tw.png'
import yt from '../assets/img/landingpage/yt.png'
import ig from '../assets/img/landingpage/ig.png'
import { useMediaQuery } from '@react-hook/media-query';

const Footer = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    return (
        <div className='px-4 lg:px-28 mt-20 font-pjs section' style={{ animationDelay: '4.5s' }}>
            {isSmallScreen ?
                <div className="flex flex-col lg:flex-row justify-between text-center lg:text-left my-12">
                    <img src={logoblack} alt='logoblack' className='w-1/2 mx-auto' />
                    <div className="flex justify-between">
                        <div className='mt-4 lg:mt-0'>
                            <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Company</h3>
                            <p className='text-[#6C757D] text-[16px] pb-2'>About Us</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Branches</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Our Blog</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Contact Us</p>
                        </div>
                        <div className='mt-4 lg:mt-0'>
                            <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Services</h3>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Hotel Flights</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Payments</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>E-commerce</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Help Center</p>
                        </div>
                        <div className='mt-4 lg:mt-0'>
                            <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Legal</h3>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Terms of Use</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Privacy Policy</p>
                            <p className='text-[#6C757D] text-[16px] pb-2'>Cookie Policy</p>
                        </div>
                    </div>
                    <div className='mt-4 lg:mt-0'>
                        <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Connect With Us</h3>
                        <p className='text-[#6C757D] text-[16px] pb-2'>support@netclixs.net</p>
                        <div className="flex gap-4 justify-center">
                            <img src={fb} alt='fb'/>
                            <img src={tw} alt='tw'/>
                            <img src={yt} alt='yt'/>
                            <img src={ig} alt='ig'/>
                        </div>
                    </div>
                </div>
            :
                <div className="flex flex-col lg:flex-row justify-between text-center lg:text-left my-12">
                    <img src={logoblack} alt='logoblack' className='lg:h-12' />
                    <div className='mt-4 lg:mt-0'>
                        <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Company</h3>
                        <p className='text-[#6C757D] text-[16px] pb-2'>About Us</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Branches</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Our Blog</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Contact Us</p>
                    </div>
                    <div className='mt-4 lg:mt-0'>
                        <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Services</h3>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Hotel Flights</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Payments</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>E-commerce</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Help Center</p>
                    </div>
                    <div className='mt-4 lg:mt-0'>
                        <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Legal</h3>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Terms of Use</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Privacy Policy</p>
                        <p className='text-[#6C757D] text-[16px] pb-2'>Cookie Policy</p>
                    </div>
                    <div className='mt-4 lg:mt-0'>
                        <h3 className='text-[#353F4F] text-[16px] font-bold pb-2'>Connect With Us</h3>
                        <p className='text-[#6C757D] text-[16px] pb-2'>support@netclixs.net</p>
                        <div className="flex gap-4 justify-center">
                            <img src={fb} alt='fb'/>
                            <img src={tw} alt='tw'/>
                            <img src={yt} alt='yt'/>
                            <img src={ig} alt='ig'/>
                        </div>
                    </div>
                </div>
            }
            <hr/>
            <p className='text-[#6C757D] text-center lg:text-left text-[12px] my-8'>© 2024 NetClixs. All Rights Reserved</p>
        </div>
    )
}

export default Footer