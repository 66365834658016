import React, { useState, useEffect } from 'react';
import next from '../assets/img/landingpage/next.png'
import retailer from '../assets/img/landingpage/retailer.png'
import dealer from '../assets/img/landingpage/dealer.png'
import center from '../assets/img/landingpage/center.png'
import blueline from '../assets/img/landingpage/blueline.png'
// import separator from '../assets/img/landingpage/separator.png'
// import airline from '../assets/img/landingpage/airline.webp'
// import cash from '../assets/img/landingpage/cash.webp'
// import bills from '../assets/img/landingpage/bills.webp'
// import loading from '../assets/img/landingpage/loading.webp'

const Everything = () => {
    const [countdownOne, setCountdownOne] = useState(0);
    const [countdownTwo, setCountdownTwo] = useState(0);
    const [countdownThree, setCountdownThree] = useState(0);
    const [pauseCountdown, setPauseCountdown] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!pauseCountdown && countdownOne < 50) {
                setCountdownOne(countdownOne + 1);
            }
        }, 100); // Update countdownOne every 100 milliseconds

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [countdownOne, pauseCountdown]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!pauseCountdown && countdownTwo < 10) {
                setCountdownTwo(countdownTwo + 1);
            }
        }, 100); // Update countdownTwo every 100 milliseconds

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [countdownTwo, pauseCountdown]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!pauseCountdown && countdownThree < 40) {
                setCountdownThree(countdownThree + 1);
            }
        }, 100); // Update countdownThree every 100 milliseconds

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [countdownThree, pauseCountdown]);

    useEffect(() => {
        // Pause the countdown for 5 seconds when any of the countdowns reach their expected number
        if (countdownOne === 50 && countdownTwo === 10 && countdownThree === 40) {
            setPauseCountdown(true);
            setTimeout(() => {
                setPauseCountdown(false);
                // Reset countdowns after 5 seconds
                setCountdownOne(0);
                setCountdownTwo(0);
                setCountdownThree(0);
            }, 2000); // Resume countdown after 5 seconds
        }
    }, [countdownOne, countdownTwo, countdownThree]);
    
    return (
        <div id="about" className='px-4 lg:px-40 mt-8 lg:mt-20 font-pjs section' style={{ animationDelay: '0.5s' }}>
            <div className="flex flex-col lg:flex-row justify-between items-center mb-20">
                <div className='w-full lg:w-1/4'>
                    <h2 className='text-[#353F4F] text-[28px] font-bold tracking-wide'>We Speck With Our Achievement & Powerful Status </h2>
                    <div className="flex gap-2 mt-2">
                        <p className='text-[#1F89FA] font-bold'>Know More</p>
                        <img src={next} alt=''/>
                    </div>
                </div>
                <img src={blueline} alt='blueline' className='h-[100px] hidden lg:block' />
                <div className="flex justify-between gap-8 lg:gap-40 mt-10 lg:mt-0">
                    <div className='text-center'>
                        <img src={retailer} alt='retailer' className='h-[60px] mx-auto'/>
                        <h1 className='text-[#353F4F] text-[28px] font-bold mt-2'>{countdownOne}k+</h1>
                        <p className='text-[#757575] text-[12px]'>Active Retailer</p>
                    </div>
                    <div className='text-center'>
                        <img src={dealer} alt='dealer' className='h-[60px] mx-auto'/>
                        <h1 className='text-[#353F4F] text-[28px] font-bold mt-2'>{countdownTwo}k+</h1>
                        <p className='text-[#757575] text-[12px]'>Active Dealer</p>
                    </div>
                    <div className='text-center'>
                        <img src={center} alt='center' className='h-[60px] mx-auto'/>
                        <h1 className='text-[#353F4F] text-[28px] font-bold mt-2'>{countdownThree}</h1>
                        <p className='text-[#757575] text-[12px]'>Active Center</p>
                    </div>
                </div>
            </div>
            {/* <img src={separator} alt='separator'/> */}
            {/* <div className="mt-8 lg:mt-20 text-center">
                <h1 className='text-[#353F4F] text-[32px] font-bold tracking-wide mb-2'>Everything in One Place</h1>
                <p className='text-[#757575] text-[16px] w-full lg:w-1/2 mx-auto'>NetClixs is an ecommerce platform that provides electronic online and mobile loading products, air ticketing, hotel booking domestic and international.</p>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 lg:grid-cols-2 lg:grid-rows-2 mt-12">
                <div className="flex gap-4 px-2 py-6">
                    <img src={airline} alt='airline' className='h-[60px]'/>
                    <div>
                        <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Hotel and Airline</h1>
                        <p className='text-[#757575] text-[14px]'>Ticketing by NetClixs offers virtual real-time travel booking services for domestic and international flights and hotel accommodation.</p>
                    </div>
                </div>
                <div className="flex gap-4 px-2 py-6">
                    <img src={bills} alt='bills' className='h-[60px]'/>
                    <div>
                        <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Bills Payment</h1>
                        <p className='text-[#757575] text-[14px]'>NetClixs payment system offers secure and convenient online bill payment, allowing you to manage all your bills anytime, anywhere. </p>
                    </div>
                </div>
                <div className="flex gap-4 px-2 py-6">
                    <img src={cash} alt='cash' className='h-[60px]'/>
                    <div>
                        <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Cash Transfer</h1>
                        <p className='text-[#757575] text-[14px]'>NetClixs offers an online cash transfer service focused on ease and convenience, enabling 24/7 money transfers to loved ones.</p>
                    </div>
                </div>
                <div className="flex gap-4 px-2 py-6">
                    <img src={loading} alt='loading' className='h-[60px]'/>
                    <div>
                        <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Online Loading</h1>
                        <p className='text-[#757575] text-[14px]'>NetClixs' E-load service enables convenient online loading of over 300 products for all networks from the comfort of your home. </p>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Everything