import React from 'react';
// import ecosystem1 from '../assets/img/landingpage/ecosystem1.png'
// import ecosystem2 from '../assets/img/landingpage/ecosystem2.png'
// import eco1 from '../assets/img/landingpage/eco1.svg'
// import eco2 from '../assets/img/landingpage/eco2.svg'
// import eco3 from '../assets/img/landingpage/eco3.svg'
// import eco4 from '../assets/img/landingpage/eco4.svg'
// import eco5 from '../assets/img/landingpage/eco5.svg'
// import eco6 from '../assets/img/landingpage/eco6.svg'
// import { animateWithGsap } from "../utils/animations";
// import { useGSAP } from "@gsap/react";
// import { useMediaQuery } from '@react-hook/media-query';
import Lottie from "lottie-react";
import ecosystem from "../assets/img/landingpage/ecosystem.json"


const WhyChoose = () => {
    // const isSmallScreen = useMediaQuery('(max-width: 768px)');
    // const [animationIndex, setAnimationIndex] = useState(0);

    // useGSAP(() => {
    //     animateWithGsap(".logo-icon", {
    //     y: 0,
    //     opacity: 1,
    //     stagger: 0.2,
    //     repeat: -1,
    //     repeatDelay: 1.3,
    //     });
    // });

    // useEffect(() => {
    //     const animateIcons = () => {
    //         // An array of CSS classes for animations
    //         const animations = ['popUp', 'popUp', 'popUp', 'popUp', 'popUp', 'popUp']; // Adjust this array based on the number of images
    
    //         let index = 0;
    //         const intervalId = setInterval(() => {
    //             // Update the state to trigger the next animation
    //             setAnimationIndex(index);
    
    //             // Increment index for the next animation
    //             index++;
    
    //             // If index exceeds the number of animations, reset it
    //             if (index >= animations.length) {
    //                 index = 0;
    //             }
    //         }, 500); // Adjust the interval duration as needed
    
    //         // Clear the interval when component unmounts
    //         return () => clearInterval(intervalId);
    //     };
    
    //     // Trigger the animation on component mount
    //     animateIcons();
    // }, []);


    return (
        <div id="services" className='px-4 lg:px-40 mt-8 lg:mt-20 font-pjs section' style={{ animationDelay: '1s' }}>
            <div className="flex flex-col lg:flex-row justify-between items-center gap-4 mb-4">
                <div className="w-full lg:w-3/43">
                    <h1 className='text-[#353F4F] text-[28px] font-bold tracking-wide mb-2 text-center lg:text-left'>Empower Your Digital Life with Netclixs Ecosystem</h1>
                    <p className='text-[#757575] text-[16px] text-center lg:text-left'>Revolutionizing online experiences with bill payments, shopping on Clixshop, and transactions via Clixspay. Seamlessly integrate social media, manage finances, and explore endless opportunities. Join us today!</p>
                </div>

                <div className="w-full lg:w-3/4 p-8">
                    <Lottie 
                        animationData={ecosystem}
                        // loop = 'false'
                        speed = {10} />
                </div>

                {/* <div style={{ width: '500px', height: '500px' }}>
                <Lottie
                    animationData={ecosystem} // Your animation data
                    loop
                    autoplay
                    style={{ width: '100%', height: '100%' }}
                />
                </div> */}

                {/* {isSmallScreen ?
                    <div className="w-full my-8 flex justify-center order-1 lg:order-2">
                        <img
                            src={ecosystem1}
                            alt=""
                            className="absolute animate-zoom"
                        />
                        <img
                            src={ecosystem2}
                            alt=""
                            className="mt-5 animate-pop"
                        />
                    </div>
                :
                    <div className="w-3/4 mt-4 flex justify-center order-1 lg:order-2">
                        <img
                            src={ecosystem1}
                            alt=""
                            className="h-[60%] lg:h-[100%] animate-zoom"
                        />

                        <div className="absolute opacity-0 lg:opacity-100 w-1/3 h-1/2 custom-lg:h-3/4">
                            <img
                            src={eco1}
                            alt=""
                            className={`absolute top-[4%] left-[38%] logo-icon w-24 ${
                                animationIndex >= 1 ? "animated" : ""
                            }`}
                            />
                            <img
                            src={eco2}
                            alt=""
                            className={`absolute top-[48%] left-[0%] logo-icon w-24 ${
                                animationIndex >= 2 ? "animated" : ""
                            }`}
                            />
                            <img
                            src={eco3}
                            alt=""
                            className={`absolute -bottom-[12%] left-[28%] logo-icon w-8 ${
                                animationIndex >= 3 ? "animated" : ""
                            }`}
                            />
                            <img
                            src={eco4}
                            alt=""
                            className={`absolute -bottom-[12%] right-[28%] logo-icon w-8 ${
                                animationIndex >= 4 ? "animated" : ""
                            }`}
                            />
                            <img
                            src={eco5}
                            alt=""
                            className={`absolute top-[54%] right-[0%] logo-icon w-24 ${
                                animationIndex >= 5 ? "animated" : ""
                            }`}
                            />
                            <img
                            src={eco6}
                            alt=""
                            className={`absolute top-[28%] left-[42%] logo-icon w-16 ${
                                animationIndex >= 6 ? "animated" : ""
                            }`}
                            />
                        </div>
                    </div>
                } */}

            </div>
            {/* <div className="flex flex-col lg:flex-row justify-between">
                <div className='w-full lg:w-1/2'>
                    <h1 className='text-[#353F4F] text-[32px] font-bold tracking-wide mb-2 text-center lg:text-left'>Why Choose NetClixs?</h1>
                    <p className='text-[#757575] text-[16px] text-center lg:text-left'>At NetClixs, we're more than just a service provider; we're your dedicated ally in simplifying life's complexities. Here's why thousands trust us daily: </p>
                    <div className="flex items-center gap-4 mt-6">
                        <img src={bullet} alt='bullet' className='h-[20px]'/>
                        <p className='text-[#757575] text-[16px]'>Access load, bill payments, and flights all in one place.</p>
                    </div>
                    <div className="flex items-center gap-4 mt-6">
                        <img src={bullet} alt='bullet' className='h-[20px]'/>
                        <p className='text-[#757575] text-[16px]'>Ensures your information is always secure.</p>
                    </div>
                    <div className="flex items-center gap-4 mt-6">
                        <img src={bullet} alt='bullet' className='h-[20px]'/>
                        <p className='text-[#757575] text-[16px]'>Discounts, rewards, and competitive prices offers.</p>
                    </div>
                    <div className="flex items-center gap-4 mt-6">
                        <img src={bullet} alt='bullet' className='h-[20px]'/>
                        <p className='text-[#757575] text-[16px]'>Real-time updates and bookings ensure you never miss out.</p>
                    </div>
                    <div className="flex justify-center lg:justify-start gap-12 mt-8">
                        <button className='text-white bg-[#3F92F9] hover:bg-transparent hover:text-[#3F92F9] border-2 border-[#3F92F9] font-semibold rounded-full py-2 px-6'>Register</button>
                        <button className="flex items-center gap-2">
                            <img src={play} alt='play' className='h-[40px]'/>
                            <p className='text-[#757575] hover:text-[#3F92F9] text-[18px] font-semibold'>Video Tutorial</p>
                        </button>
                    </div>
                </div>
                <img src={icon} alt='icon' className='h-100 lg:h-[450px] px-8 mt-8 lg:mt-0 floatingEntrance'/>
            </div> */}
        </div>
    )
}

export default WhyChoose