import React from 'react'
import { useNavigate } from 'react-router-dom';
import back from '../assets/img/dashboard/back.svg'

const CashEwalletConfirmation = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); 
    };


    return (
        <div>
            <img src={back} alt='' className='mb-4 cursor-pointer' onClick={handleBackClick}/>
            <div className="flex justify-center ">
                <div className="bg-white rounded-2xl p-8 w-full lg:w-1/3 h-1/2">
                    <h2 className='text-[18px] text-[#334155] font-bold text-center mb-4'>Confirm Transaction</h2>
                    <div className="flex justify-between mb-4">
                        <p className='text-[14px] text-[#09090B] font-bold'>Amount</p>
                        <p className='text-[18px] text-[#334155] font-bold'>P 1,200.00</p>
                    </div>
                    <div className="flex justify-between mb-4">
                        <p className='text-[14px] text-[#09090B] font-bold'>Operator</p>
                        <p className='text-[18px] text-[#334155] font-bold'>Gcash</p>
                    </div>
                    <div className="flex justify-between mb-4">
                        <p className='text-[14px] text-[#09090B] font-bold'>Account Name</p>
                        <p className='text-[18px] text-[#334155] font-bold'>Loven Judge</p>
                    </div>
                    <div className="flex justify-between mb-4">
                        <p className='text-[14px] text-[#09090B] font-bold'>Account No.</p>
                        <p className='text-[18px] text-[#334155] font-bold'>09123456789</p>
                    </div>
                    <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold'>Send</button>
                </div>
            </div>
        </div>
    )
}

export default CashEwalletConfirmation