import React from 'react'

const StayUpdate = () => {
  return (
    <div className='m-8 lg:my-20 lg:mx-32 font-pjs bg-stay rounded-3xl section' style={{ animationDelay: '4s' }}>
        <div className="flex flex-col lg:flex-row justify-between items-center mt-8 lg:mt-40 px-8 lg:px-28 py-12">
            <h1 className='text-white text-[28px] font-bold tracking-wide w-full lg:w-1/2'>Stay up to date with our news, ideas and updates</h1>
            <div className='flex flex-col lg:flex-row gap-4 mt-4'>
                <input type="text" placeholder="Your email address" className="border rounded-md px-4 py-2 h-12 w-60" />
                <button className='text-white text-[14px] bg-[#71717A] rounded-full py-2 px-4 h-12'>Subscribe now</button>
            </div>
        </div>
    </div>
  )
}

export default StayUpdate