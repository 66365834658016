import React from 'react';
import { useNavigate } from 'react-router-dom';

const historyTransaction = [
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'In Progress',
  },
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },

  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },
  {
    type: 'Transfer',
    txid: '13141234123141231235123',
    amount: '100.00',
    date: 'Jan 19 , 2014',
    status: 'Successful',
  },
];


const Cash = () => {
    const navigate = useNavigate();
    const handleTransfer = () => {
      navigate('/cash/transfer');
    };
    const handleFund = () => {
      navigate('/request');
    };
  
    return (
      <div>
          <div className="w-full lg:w-1/4 mb-8 bg-white rounded-2xl p-4">
            <div className="bg-gradient-to-b from-[#19ACED] to-[#0E32CF] rounded-2xl py-6 px-4 text-center">
              <p className='text-white text-[14px]'>Available Funds</p>
              <h1 className='text-white text-[32px] font-bold mb-4'>₱ 1200.00</h1>              
            </div>
            <button className='bg-[#F3F3F3] rounded-lg p-2 text-[14px] text-[#333333] font-bold w-full mt-4' onClick={handleTransfer}>Transfer</button>
            <button className='bg-[#F3F3F3] rounded-lg p-2 text-[14px] text-[#333333] font-bold w-full mt-4' onClick={handleFund}>Request Fund</button>

          </div>
          <div className="bg-white rounded-lg p-4 mb-4">
            <div className="flex justify-between items-center p-2">
              <h1 className='text-[18px]  lg:text-[20px] text-[#09090B] font-bold mb-2'>Transaction History</h1>
              <p className='text-[10px] lg:text-[14px] text-[#71717A]'>Transaction Type</p>
            </div>
            <div className="overflow-x-auto">
                <table className="table">
                    <thead>
                        <tr>
                            <th className='text-[#0F172A] text-[16px] font-bold'>Type of Transaction</th>
                            <th className='text-[#0F172A] text-[16px] font-bold'>TXID</th>
                            <th className='text-[#0F172A] text-[16px] font-bold'>Amount</th>
                            <th className='text-[#0F172A] text-[16px] font-bold'>Date</th>
                            <th className='text-[#0F172A] text-[16px] font-bold'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyTransaction.map((data, index) => (
                        <tr key={index}>
                            <td>{data.type}</td>
                            <td>{data.txid}</td>
                            <td>{data.amount}</td>
                            <td>{data.date}</td>
                            <td className={data.status.startsWith('I') ? 'text-[#FCB54D] font-medium' : data.status.startsWith('S') ? 'text-[#047857] font-medium' : ''}>
                              {data.status}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
          </div>
      </div>
    )
}

export default Cash