import React, { useRef } from 'react';

import booth from '../assets/img/landingpage/booth.webp';
// import driving from "../assets/img/landingpage/permit.svg";
// import flightPackage from "../assets/img/landingpage/package.svg";
// import insurance from "../assets/img/landingpage/insurance.svg";
// import commission from "../assets/img/landingpage/commission.svg";
// import assisstance from "../assets/img/landingpage/assistance.svg";
// import services from "../assets/img/landingpage/services.svg";
import { animateWithGsap } from "../utils/animations";
import { useGSAP } from "@gsap/react";
// import gsap from "gsap/gsap-core";
import airline from '../assets/img/landingpage/airline.webp'
import cash from '../assets/img/landingpage/cash.webp'
import bills from '../assets/img/landingpage/bills.webp'
import loading from '../assets/img/landingpage/loading.webp'

const Personalize = () => {
  // const [isHovered, setIsHovered] = useState(false);
  const imageRef = useRef(null);
  const handleMouseMove = (e) => {
    const { left, top, width, height } = imageRef.current.getBoundingClientRect();
    const x = e.clientX - left - width / 2;
    const y = e.clientY - top - height / 2;
    const rotationX = -(y / height) * 10;
    const rotationY = (x / width) * 10;
    imageRef.current.style.transform = `perspective(1000px) rotateX(${rotationX}deg) rotateY(${rotationY}deg)`;
  };

  // console.log(isHovered);

  const handleMouseLeave = () => {
    imageRef.current.style.transform = 'none';
  };

    useGSAP(() => {
      animateWithGsap(["#booth-img", "#booth-img2"], {
        x: 0,
        opacity: 1,
        stagger: 0.2,
      });
    });

  return (
    <div
      className="bg-ticketing py-4 lg:px-40 mt-20 font-pjs section"
      style={{ animationDelay: "1.5s" }}
    >
      <div className="text-center relative  px-4">
        <h1 className="text-[#353F4F] text-[28px] font-bold tracking-wide mb-2 w-full lg:w-1/2 mx-auto">
          Unlock Your Own Payment and Ticketing Center with Us!
        </h1>
        <p className="text-[#757575] text-[16px] mb-8 w-full lg:w-1/3 mx-auto">
          Purchase Tickets and Make Payments Hassle-Free, Anywhere You Want
        </p>

        {/* <div
          id="bubbles"
          className="opacity-0 md:opacity-100 absolute left-0 right-0  h-3/4 w-2/3 mx-auto z-10"
        >
          <div className="flex justify-between items-center w-auto h-full">
            <div className="flex flex-col gap-10  justify-between  items-center h-full py-10">
              <div className="flex ml-12">
                <h1 className=" text-[#353F4F] text-[24px] font-bold bg-white text-center align-center my-auto">
                  Driving Permit
                </h1>
                <img
                  id="booth-img"
                  src={driving}
                  alt=""
                  className="w-24 h-auto "
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </div>

              {isHovered && (
                <div className="absolute left-200 top-0 bg-gray-200 p-2 rounded-md transition-all duration-300 ease-in-out z-10">
                  <p className="ml-auto">
                    This is the text you want to show
                  </p>
                </div>
              )}

              <img
                id="booth-img"
                src={flightPackage}
                alt=""
                className="w-24 h-auto self-start"
              />
              <img
                id="booth-img"
                src={insurance}
                alt=""
                className="w-24 h-auto  ml-12"
              />
            </div>
            <div className="flex flex-col justify-between  items-center gap-10 h-full py-10">
              <img
                id="booth-img2"
                src={commission}
                alt=""
                className="w-24 h-auto mr-12"
              />
              <img
                id="booth-img2"
                src={assisstance}
                alt=""
                className="w-24 h-auto self-end"
              />
              <img
                id="booth-img2"
                src={services}
                alt=""
                className="w-24 h-auto mr-12"
              />
            </div>
          </div>
        </div>
        <img
          src={booth}
          alt="booth"
          className="w-1/2 mx-auto relative z-0"
          ref={imageRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        /> */}

        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className='order-2 lg:order-1'>
              <div id="booth-img" className="flex gap-4 px-2 py-6">
                  <div className='order-2 lg:order-1'>
                      <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Hotel and Airline</h1>
                      <p className='text-[#757575] text-[14px]'>Ticketing by NetClixs offers virtual real-time travel booking services for domestic and international flights and hotel accommodation.</p>
                  </div>
                  <img src={airline} alt='airline' className='h-[60px] order-1 lg:order-2'/>
              </div>
              <div id="booth-img" className="flex gap-4 px-2 py-6">
                  <div className='order-2 lg:order-1'>
                      <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Bills Payment</h1>
                      <p className='text-[#757575] text-[14px]'>NetClixs payment system offers secure and convenient online bill payment, allowing you to manage all your bills anytime, anywhere. </p>
                  </div>
                  <img src={bills} alt='bills' className='h-[60px] order-1 lg:order-2'/>
              </div>
          </div>
          <div className="order-1 lg:order-2">
            <img
              src={booth}
              alt="booth"
              className="w-full mx-auto relative z-0"
              ref={imageRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
          </div>
          <div className='order-3'>
            <div id="booth-img2" className="flex gap-4 px-2 py-6">
                <img src={cash} alt='cash' className='h-[60px]'/>
                <div>
                    <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Cash Transfer</h1>
                    <p className='text-[#757575] text-[14px]'>NetClixs offers an online cash transfer service focused on ease and convenience, enabling 24/7 money transfers to loved ones.</p>
                </div>
            </div>
            <div id="booth-img2" className="flex gap-4 px-2 py-6">
                <img src={loading} alt='loading' className='h-[60px]'/>
                <div>
                    <h1 className='text-[#353F4F] text-[20px] font-bold mb-2'>Online Loading</h1>
                    <p className='text-[#757575] text-[14px]'>NetClixs' E-load service enables convenient online loading of over 300 products for all networks from the comfort of your home. </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};  

export default Personalize;
