import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import logoblack from "../assets/img/landingpage/logoblack.webp"
// import logowhite from "../assets/img/landingpage/logowhite.webp"
import home from "../assets/img/dashboard/home.svg"
import dashboard from "../assets/img/dashboard/dashboard.svg"
import airline from "../assets/img/dashboard/airline.svg"
import online from "../assets/img/dashboard/online.svg"
import bills from "../assets/img/dashboard/bills.svg"
import cash from "../assets/img/dashboard/cash.svg"
import request from "../assets/img/dashboard/request.svg"
import profile from "../assets/img/dashboard/profile.svg"
import book from "../assets/img/dashboard/book.svg"

export default function Sidebar() {
    const [activeItem, setActiveItem] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation(); 

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        setSidebarOpen(false);
    };

    useEffect(() => {
        const pathname = location.pathname;
        setActiveItem(getActiveItemFromPathname(pathname));
    }, [location.pathname]); 


    const getActiveItemFromPathname = (pathname) => {
        switch (pathname) {
            case '/home':
                return 'home';
            case '/dashboard':
                return 'dashboard';
            case '/airline':
                    return 'airline';
            case '/online':
                return 'online';
            case '/bills':
                return 'bills';
            case '/cash':
                return 'cash';
            case '/request':
                return 'request';
            case '/profile':
                return 'profile';
            case '/marketing-tools':
                return 'marketing';
            default:
                return null;
        }
    };
    



    return (
        <> 
            <button 
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-600"
            >
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>
            
            <aside 
                id="cta-button-sidebar" 
                className={`bg-[#E7EEF8] fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${sidebarOpen ? 'translate-x-0 bg-[#19ACED]' : '-translate-x-full'} sm:translate-x-0`} 
                aria-label="Sidebar"
            >
                <div className="p-3">
                    <img src={logoblack} alt='' className='h-14'/>

                    {/* {sidebarOpen ? (
                        <img src={logowhite} alt='' className='h-14'/>
                    ) : (
                        <img src={logoblack} alt='' className='h-14'/>
                    )} */}
                </div>

                <div className="h-full px-3 py-4 overflow-y-auto bg-gradient-to-b from-[#19ACED] to-[#0E32CF] rounded-tr-3xl rounded-br-3xl">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <a href="/home" onClick={() => handleItemClick('home')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'home' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                                <img src={home} alt=''/>
                                <span className="ms-3">Home</span>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard" onClick={() => handleItemClick('dashboard')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'dashboard' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                                <img src={dashboard} alt=''/>
                                <span className="flex-1 ms-3 whitespace-nowrap">Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a href="/airline" onClick={() => handleItemClick('airline')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'airline' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                            <img src={airline} alt=''/>
                            <span className="flex-1 ms-3 whitespace-nowrap">Airline & Hotel</span>
                            </a>
                        </li>
                        <li>
                            <a href="/online" onClick={() => handleItemClick('online')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'online' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                            <img src={online} alt=''/>
                            <span className="flex-1 ms-3 whitespace-nowrap">Online Loading</span>
                            </a>
                        </li>
                        <li>
                            <a href="/bills" onClick={() => handleItemClick('bills')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'bills' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                            <img src={bills} alt=''/>
                            <span className="flex-1 ms-3 whitespace-nowrap">Bills Payment</span>
                            </a>
                        </li>
                        <li>
                            <a href="/cash" onClick={() => handleItemClick('cash')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'cash' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                            <img src={cash} alt=''/>
                            <span className="flex-1 ms-3 whitespace-nowrap">Cash Transfer</span>
                            </a>
                        </li>
                        <li>
                            <a href="/request" onClick={() => handleItemClick('request')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'request' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                            <img src={request} alt=''/>
                            <span className="flex-1 ms-3 whitespace-nowrap">Request Fund</span>
                            </a>
                        </li>
                        <li>
                            <a href="/profile" onClick={() => handleItemClick('profile')} className={`flex items-center p-2 text-white rounded-lg group ${activeItem === 'profile' ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-20'}`}>
                            <img src={profile} alt=''/>
                            <span className="flex-1 ms-3 whitespace-nowrap">Profile</span>
                            </a>
                        </li>
                    </ul>
                    <div className="fixed bottom-0 p-4">
                        <a href="/marketing-tools" onClick={() => handleItemClick('marketing')} >
                            <div className="flex gap-5 bg-white bg-opacity-20 rounded-2xl py-4 px-8">
                                <img src={book} alt=''/>
                                <div>
                                    <h1 className='text-white'>Marketing </h1>
                                    <h1 className='text-white'>Tools </h1>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </aside>
        </>
    );
}