import React from "react";

const Topbar = () => {

  return (
    <div>
        <div className="flex flex-col lg:flex-row justify-between p-4 lg:py-4 lg:px-8 ">
            <div>
                <p className="text-[14px] text-[#707EAE]">Hi Charles,</p>
                <h1 className="text-[26px] lg:text-[30px] text-[#2B3674] font-bold">Welcome to Netclixs!</h1>
            </div>
            <label className="input flex items-center gap-2 w-full lg:w-1/4">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="4.3" stroke="#1B2559" strokeWidth="1.4"/>
                    <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#1B2559" strokeWidth="1.4" strokeLinecap="round"/>
                </svg>
                <input type="text" className="text-[12px] text-[#8F9BBA]" placeholder="Search" />
            </label>
        </div>
    </div>
  );
};

export default Topbar