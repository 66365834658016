window.addEventListener('scroll', function() {
  var navbar = document.querySelector('.navbar');

  // Check if navbar element exists
  if (navbar) {
      if (window.scrollY > 0) {
          navbar.classList.add('navbar-scrolled');
      } else {
          navbar.classList.remove('navbar-scrolled');
      }
  }
});


// document.addEventListener('DOMContentLoaded', function() {

//   var navbar = document.querySelector('.navbar');
//   if (navbar) {
//     window.addEventListener('scroll', function() {
//       if (window.scrollY > 0) {
//         navbar.classList.add('navbar-scrolled');
//       } else {
//         navbar.classList.remove('navbar-scrolled');
//       }
//     });

//     var menuElement = document.getElementById('menu');
//     if (menuElement) {
//       // Perform actions with menuElement
//     } else {
//       console.error('Element with ID "menu" not found.');
//     }
//   } else {
//     console.error('Navbar element not found.');
//   }
// });
