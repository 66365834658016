import React, { useState } from 'react';
import smart from "../assets/img/dashboard/smart.png"
import close from "../assets/img/dashboard/close.svg"
import warning from "../assets/img/dashboard/warning.svg"

const load = [
  {
    img: smart,
    inclusion: "2GB + 1 GB EVERY DAY",
    promo: "Smart GIGA Video 99",
    price: "PHP 99",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "1 GB + 1 GB/day for ML...",
    promo: "Smart GIGA Games 50",
    price: "PHP 50",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "2GB + 1 GB EVERY DAY",
    promo: "Smart GIGA Stories 99",
    price: "PHP 99",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "2GB + 1 GB EVERY DAY",
    promo: "Smart GIGA Video 99",
    price: "PHP 99",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "1 GB + 1 GB/day for ML...",
    promo: "Smart GIGA Games 50",
    price: "PHP 50",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "2GB + 1 GB EVERY DAY",
    promo: "Smart GIGA Stories 99",
    price: "PHP 99",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "2GB + 1 GB EVERY DAY",
    promo: "Smart GIGA Video 99",
    price: "PHP 99",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "1 GB + 1 GB/day for ML...",
    promo: "Smart GIGA Games 50",
    price: "PHP 50",
    more: "More details",
  },
  {
    img: smart,
    inclusion: "2GB + 1 GB EVERY DAY",
    promo: "Smart GIGA Stories 99",
    price: "PHP 99",
    more: "More details",
  }
];

const Online = () => {
    const [activeLoad, setActiveLoad] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLoad = () => {
      setActiveLoad((prevActive) => !prevActive);
    };
    const toggleModal = () => {
      setIsModalOpen(prevState => !prevState);
    };

    return (
      <>
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="bg-white rounded-2xl p-4 w-full lg:w-1/3 h-1/2">
            <div className="bg-gradient-to-b from-[#19ACED] to-[#0E32CF] rounded-2xl p-4 text-center">
              <p className='text-white text-[14px]'>Available Funds</p>
              <h1 className='text-white text-[32px] font-bold mb-4'>₱ 1200.00</h1>
              <div className="flex justify-between">
                <p className='text-white text-[14px]'>Loading Transaction</p>
                <h6 className='text-white text-[14px] font-bold'>₱ 917.26</h6>
              </div>
              <div className="flex justify-between">
                <p className='text-white text-[14px]'>Loading Transaction</p>
                <h6 className='text-white text-[14px] font-bold'>₱ 917.26</h6>
              </div>
            </div>
            <div className="flex gap-1 bg-[#F1F5F9] p-2 rounded-full w-full my-4">
                <button
                    className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl ${
                        activeLoad
                        ? "bg-[#FFFFFF] text-[#09090B] w-full font-semibold"
                        : "bg-transparent text-[#334155] w-full"
                    }`}
                    onClick={handleLoad}
                >
                Prepaid
                </button>
                <button
                className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl ${
                    activeLoad
                    ? "bg-transparent text-[#334155] w-full"
                    : "bg-[#FFFFFF] text-[#09090B] w-full font-semibold"
                }`}
                onClick={handleLoad}
                >
                Epins Load
                </button>
            </div>
            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Mobile Number</p>
            <input type="text" placeholder="09123456789" className="input input-bordered input-sm w-full max-w-xs mb-4 rounded-xl text-[#334155] font-bold" disabled />
            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Operator</p>
            <select className="select select-bordered select-sm w-full max-w-xs mb-4 rounded-xl text-[#334155] font-bold">
              <option disabled selected>Smart Communication</option>
              <option>Globe Telcom</option>
              <option>Talk N' Text</option>
              <option>TM</option>
            </select>
            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Subscribe to</p>
            <input type="text" placeholder="Type here" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
            <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold'>Purchase</button>
          </div>

          <div className="grid grid-cols-2 grid-rows-2 lg:grid-cols-3 lg:grid-rows-3 w-full gap-2 lg:w-3/4">
            {load.map((data, index) => (
                <div key={index} className="bg-white rounded-3xl p-4 mb-4 text-center w-full lg:w-3/4">
                  <img src={data.img} alt='' className='mx-auto h-8 w-8' />
                  <p className='text-[#71717A] text-[12px] my-2'>{data.inclusion}</p>
                  <h1 className='text-[#18181B] text-[16px] font-bold'>{data.promo}</h1>
                  <button className='text-white bg-[#006EF0] rounded-xl w-full my-2' onClick={toggleModal}>{data.price}</button>
                  <p className='text-[#71717A] text-[12px]'>{data.more}</p>
                  {isModalOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-5">
                      <div className="bg-white p-6 rounded-lg">
                            <div className="flex justify-between items-center mb-4">
                                <h1 className='text-[#18181B] text-[20px] font-bold'>{data.promo}</h1>
                                <img src={close} alt='' className='cursor-pointer' onClick={toggleModal} />
                            </div>
                            <div className="flex justify-center items-center bg-[#FDE4E3] border border-[#FFCBC9] p-2 rounded-lg gap-2 my-4">
                                <img src={warning} alt='' />
                                <h1 className='text-[#DC2626] text-[12px] font-bold'>No Refunds for incorrect Service Input.</h1>
                            </div>
                            <div className="text-start">
                              <div className='w-full'>
                                <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-4'>Subscribe to</p>
                                <input type="text" placeholder="Type here" value={data.promo} class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
                              </div>
                              <div className="flex gap-2 w-full">
                                <div className='w-full'>
                                  <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-4'>Load Price</p>
                                  <input type="text" placeholder="Type here" value={data.price} class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
                                </div>
                                <div className='w-full'>
                                  <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-4'>Discount</p>
                                  <input type="text" placeholder="Type here" value="₱ 1.20" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
                                </div>
                              </div>
                              <div className='w-full'>
                                <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-4'>Validity</p>
                                <input type="text" placeholder="Type here" value="3 days" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
                              </div>
                              <div className='w-full'>
                                <p className='text-[#09090B] text-[12px] font-medium mb-1 mt-4'>Details</p>
                                <input type="text" placeholder="Type here" value={data.inclusion} class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
                              </div>
                            </div>
                            <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold'>Select</button>
                      </div>
                    </div>
                  )}
                </div>
            ))}
          </div>
        </div>
      </>
    )
}

export default Online