import React, { useState } from 'react';
import banner1 from "../assets/img/dashboard/banner1.webp"
import banner2 from "../assets/img/dashboard/banner2.webp"
import banner3 from "../assets/img/dashboard/banner3.webp"
import banner4 from "../assets/img/dashboard/banner4.webp"
import banner5 from "../assets/img/dashboard/banner5.webp"
import banner6 from "../assets/img/dashboard/banner6.webp"
import banner7 from "../assets/img/dashboard/banner7.webp"
import banner8 from "../assets/img/dashboard/banner8.webp"
import poster1 from "../assets/img/dashboard/poster1.png"
import closewhite from "../assets/img/dashboard/closewhite.png"

const transaction = [
    {
        img: banner1,
        imgposter: poster1,
        title: 'General Banner 1',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Retailer'
    },
    {
        img: banner2,
        imgposter: poster1,
        title: 'General Banner 2',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Center'
    },
    {
        img: banner3,
        imgposter: poster1,
        title: 'General Banner 3',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Center'
    },
    {
        img: banner4,
        imgposter: poster1,
        title: 'General Banner 4',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Retailer'
    },
    {
        img: banner5,
        imgposter: poster1,
        title: 'General Banner 5',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Dealer'
    },
    {
        img: banner6,
        imgposter: poster1,
        title: 'General Banner 6',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Retailer'
    },
    {
        img: banner7,
        imgposter: poster1,
        title: 'General Banner 7',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Retailer'
    },
    {
        img: banner8,
        imgposter: poster1,
        title: 'General Banner 9',
        date: 'April 29, 2024',
        type: 'Poster',
        account: 'Dealer'
    },
];


const Marketing = () => {
    const [activeTab, setActiveTab] = useState('All');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalIndex, setModalIndex] = useState(null);
    const filteredTransactions = activeTab === 'All' ? transaction : transaction.filter(data => data.account === activeTab);
    const handleClick = (tab) => {
        setActiveTab(tab);
    };
    const toggleModal = (index) => {
        setIsModalOpen(!isModalOpen);
        setModalIndex(index);
    };

  return (
    <div>
        <div className="bg-[#F8F8F8] rounded-lg p-8 h-100">
            <div role="tablist" className="tabs gap-4 flex items-center overflow-x-auto">
                <p className='text-[#09090B] font-bold text-[16px] whitespace-nowrap'>Account Type</p>
                <button
                    role="tab"
                    className={`tab text-[16px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                        activeTab === 'All' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                    }`}
                    onClick={() => handleClick('All')}
                >
                All
                </button>
                <button
                    role="tab"
                    className={`tab text-[16px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                        activeTab === 'Retailer' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                    }`}
                    onClick={() => handleClick('Retailer')}
                >
                Retailer
                </button>
                <button
                    role="tab"
                    className={`tab text-[16px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                        activeTab === 'Dealer' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                    }`}
                    onClick={() => handleClick('Dealer')}
                >
                Dealer
                </button>
                <button
                    role="tab"
                    className={`tab text-[16px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                        activeTab === 'Center' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                    }`}
                    onClick={() => handleClick('Center')}
                >
                Center
                </button>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 lg:grid-cols-4 lg:grid-rows-4 gap-4 my-4">
                {filteredTransactions.map((data, index) => (
                    <div key={index}>
                        <div className="bg-[#FFFFFF] rounded-lg p-4 w-full">
                            <img src={data.img} alt='' className='w-[100vw] h-100'/>
                            <h1 className='text-[20px] text-[#18181B] font-bold mb-2'>{data.title}</h1>
                            <p className='text-[14px] text-[#71717A]'>{data.date}</p>
                            <p className='text-[14px] text-[#71717A]'>{data.type}</p>
                            <button className='bg-[#006EF0] p-2 rounded-full text-white w-full my-4' onClick={() => toggleModal(index)}>Open</button>
                        </div>
                        {isModalOpen && modalIndex === index && (
                            <div className="fixed inset-0 z-50 bg-black bg-opacity-80 p-4">
                                <div className="flex justify-end mb-4">
                                    <img src={closewhite} alt='' className='cursor-pointer' onClick={() => toggleModal(index)}/>
                                </div>
                                <img src={data.imgposter} alt='' className='h-[80vh] lg:h-[90vh] mx-auto'/>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Marketing