import React from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../assets/img/dashboard/back.svg'

const Request = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/request/confirmation');
    };
    const handleBackClick = () => {
        navigate(-1); 
    };

    return (
        <div>
            <img src={back} alt='' className='mb-4 cursor-pointer' onClick={handleBackClick}/>
            <div className="flex justify-center ">
                <div className="bg-white rounded-2xl p-8 w-full lg:w-1/3 h-1/2">
                      <h1 className='text-center text-[#334155] text-[16px] font-bold mb-4'>Request Fund</h1>
                      <p className='text-[#09090B] text-[12px] font-medium mb-1'>Operator</p>
                      <select className="select select-bordered select-sm w-full max-w-xs mb-4 rounded-xl text-[#334155] font-bold">
                          <option selected>Gcash</option>
                          <option>Paymaya</option>
                          <option>Paypal</option>
                      </select>

                      <p className='text-[#09090B] text-[12px] font-medium mb-1'>Amount</p>
                      <input type="number" placeholder="" className="input input-sm w-full max-w-xs mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />
                      
                      <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold' onClick={handleClick}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default Request