import React from 'react';
import imgOne from '../assets/img/landingpage/imgOne.webp';
import imgTwo from '../assets/img/landingpage/imgTwo.webp';
import imgThree from '../assets/img/landingpage/imgThree.webp';
import imgFour from '../assets/img/landingpage/imgFour.webp';
import logo from '../assets/img/landingpage/logo.webp';
import Carousel from './Carousel';

const ForgotPassword = () => {
  const imageUrls = [imgOne, imgTwo, imgThree, imgFour];

  return (
    <>
      <div
        className="fixed inset-0 z-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${imageUrls[0]})`,
        }}
      ></div>
      <Carousel imageUrls={imageUrls} className="hidden" />
      <div className="relative p-8 font-dm">
        <a href='/'><img src={logo} alt='' className='w-32'/></a>
        <div className="flex justify-center w-full h-full items-center mt-4">
          <div className="bg-white rounded-lg w-full lg:w-2/5 py-10 px-4 lg:px-20 z-10">
            <h1 className="text-center text-[#333333] text-3xl font-bold mb-4">Forgot password</h1>
            <p className="text-center text-[14px] text-[#666666] mb-6"><a href='/sign-in'>Back to sign in</a></p>
            <div className="mb-4">
              <h6 className='text-[#666666] text-[14px]'>Enter email address</h6>
              <input type="email" className="border rounded-md px-4 py-2 h-12 w-full" />
            </div>
            <div className="mb-4">
              <button className='bg-[#006FEE] w-full rounded-full py-2 text-white mt-2'>Send</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
