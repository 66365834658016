import React, { useState } from 'react';
import close from "../assets/img/dashboard/close.svg"
import clogo from "../assets/img/dashboard/clogo.png"
import dots from "../assets/img/dashboard/dots.png"



const historyTransaction = [
  {
      action: 'Bills payment',
      biller: 'CIGNAL',
      balance: '0.00',
      date: 'Jan 19 , 2014',
      amount: '- 800.00',
  },
  {
    action: 'Bills payment',
    biller: 'CIGNAL',
    balance: '800.00',
    date: 'Jan 19 , 2014',
    amount: '- 750.00',
  },
  {
    action: 'Add Fund',
    biller: 'CIGNAL',
    balance: '1550.00',
    date: 'Jan 19 , 2014',
    amount: '- 1,500.00',
  },
  {
    action: 'Add Fund',
    biller: 'CIGNAL',
    balance: '50.00',
    date: 'Jan 19 , 2014',
    amount: '+ 800.00',
  },
  {
    action: 'Bills payment',
    biller: 'CIGNAL',
    balance: '750.00',
    date: 'Jan 19 , 2014',
    amount: '+ 500.00',
  },
];

const billsTransaction = [
  {
      biller: 'CIGNAL',
      account: 6054283,
      name: 'Claudia Cubos',
      datepaid: 'Jan 19 , 2014',
      amount: '- 800.00',
  },
  {
    biller: 'CIGNAL',
    account: 6054283,
    name: 'Claudia Cubos',
    datepaid: 'Jan 19 , 2014',
    amount: '- 750.00',
  },
  {
    biller: 'CIGNAL',
    account: 6054283,
    name: 'Claudia Cubos',
    datepaid: 'Jan 19 , 2014',
    amount: '- 1,500.00',
  },
  {
    biller: 'CIGNAL',
    account: 6054283,
    name: 'Claudia Cubos',
    datepaid: 'Jan 19 , 2014',
    amount: '+ 800.00',
  },
  {
    biller: 'CIGNAL',
    account: 6054283,
    name: 'Claudia Cubos',
    datepaid: 'Jan 19 , 2014',
    amount: '+ 500.00',
  },
];

const moneyTransaction = [
  {
    target: '099988886',
    mobile: '0999888868',
    amount: '800.00',
    charge: '- 100.00',
    date: 'Jan 19 , 2014',
    remarks: 'Remarks..',
  },
  {
    target: '099988886',
    mobile: '09020897839',
    amount: '800.00',
    charge: '- 100.00',
    date: 'Jan 19 , 2014',
    remarks: 'Remarks..',
  },
  {
    target: '099988886',
    mobile: '09020897839',
    amount: '800.00',
    charge: '- 100.00',
    date: 'Jan 19 , 2014',
    remarks: 'Remarks..',
  },
  {
    target: '099988886',
    mobile: '09020897839',
    amount: '800.00',
    charge: '- 100.00',
    date: 'Jan 19 , 2014',
    remarks: 'Remarks..',
  },
  {
    target: '099988886',
    mobile: '09020897839',
    amount: '800.00',
    charge: '- 100.00',
    date: 'Jan 19 , 2014',
    remarks: 'Remarks..',
  },
];

const Bills = () => {
  const [activeTab, setactiveTab] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTab = () => {
    setactiveTab((prevActive) => !prevActive);
  };  
  const toggleModal = () => {
    setIsModalOpen(prevState => !prevState);
  };

  
  return (
    <div>
      <div className="flex flex-col lg:flex-row justify-between gap-4">
        <div className="w-full lg:w-2/3 gap-4">
          <h1 className='text-[#101010] text-[30px] font-bold mb-4'>Bills Payment</h1>
          <div className="flex flex-col lg:flex-row justify-between bg-white rounded-lg py-6 px-4 lg:px-12 mb-4">
              <div className="lg:border-r lg:border-[#A1A1AA] px-4 lg:px-12 flex-grow mb-4 lg:mb-0">
                <p className='text-[#71717A] text-[14px] font-semibold'>Transaction</p>
                <p className='text-[#101010] text-[14px] font-semibold'>₱ 1200.00</p>
              </div>
              <div className="lg:border-r lg:border-[#A1A1AA] px-4 lg:px-12 flex-grow mb-4 lg:mb-0">
                <p className='text-[#71717A] text-[14px] font-semibold'>Transaction Override</p>
                <p className='text-[#101010] text-[14px] font-semibold'>₱ 1200.00</p>
              </div>
              <div className="px-4 lg:px-12">
                <p className='text-[#71717A] text-[14px] font-semibold flex-grow'>Bill Payment Funds</p>
                <p className='text-[#101010] text-[14px] font-semibold'>₱ 1200.00</p>
              </div>
          </div>
          <div className="flex gap-1 bg-[#F1F5F9] p-2 rounded-full w-full lg:w-1/3 mb-4">
              <button
                  className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl whitespace-nowrap ${
                      activeTab
                      ? "bg-[#006FEE] text-[#FFFFFF] w-full"
                      : "bg-transparent w-full"
                  }`}
                  onClick={handleTab}
              >
              History
              </button>
              <button
              className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl whitespace-nowrap ${
                  activeTab
                  ? "bg-transparent w-full"
                  : "bg-[#006FEE] text-[#FFFFFF] w-full"
              }`}
              onClick={handleTab}
              >
              Services Reports
              </button>
          </div>
          {activeTab ? (
            <div className="bg-white rounded-lg p-4 mb-4">
              <h1 className='text-[18px] lg:text-[20px] text-[#09090B] font-bold mb-2'>Bills Payment Transaction</h1>
              <div className="overflow-x-auto">
                  <table className="table">
                      <thead>
                          <tr>
                              <th className='text-[#0F172A] text-[16px] font-bold'>Action</th>
                              <th className='text-[#0F172A] text-[16px] font-bold'>Biller</th>
                              <th className='text-[#0F172A] text-[16px] font-bold'>Balance</th>
                              <th className='text-[#0F172A] text-[16px] font-bold'>Date</th>
                              <th className='text-[#0F172A] text-[16px] font-bold'>Amount</th>
                          </tr>
                      </thead>
                      <tbody>
                          {historyTransaction.map((data, index) => (
                          <tr key={index}>
                              <td>{data.action}</td>
                              <td>{data.biller}</td>
                              <td>{data.balance}</td>
                              <td>{data.date}</td>
                              <td className={data.amount.startsWith('-') ? 'text-[#DC2626] font-medium' : data.amount.startsWith('+') ? 'text-[#05CD99] font-medium' : ''}>
                                {data.amount}
                              </td>
                          </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
            </div>
          ) : (
            <>
              <div className="bg-white rounded-lg p-4 mb-4">
                <h1 className='text-[18px] lg:text-[20px] text-[#09090B] font-bold mb-2'>Bills Payment Transaction</h1>
                <div className="overflow-x-auto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Biller</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Account</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Acc. Name</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Date Paid</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Amount</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billsTransaction.map((data, index) => (
                            <tr key={index}>
                                <td>{data.biller}</td>
                                <td className='text-[#006FEE] font-medium'>{data.account}</td>
                                <td>{data.name}</td>
                                <td>{data.datepaid}</td>
                                <td className={data.amount.startsWith('-') ? 'text-[#DC2626] font-medium' : data.amount.startsWith('+') ? 'text-[#05CD99] font-medium' : ''}>
                                  {data.amount}
                                </td>
                                <td>
                                  <button className='bg-[#006FEE] rounded-full text-[#E7EEF8] px-4' onClick={toggleModal}>View</button>  
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
                {isModalOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 p-8">
                      <div className="bg-white p-6 rounded-lg text-center w-full lg:w-1/4">
                            <div className="flex justify-end">
                              <img src={close} alt='' className='cursor-pointer' onClick={toggleModal}/>
                            </div>
                            <h1 className='text-[20px] text-[#333333] mb-4'>Payment Success To</h1>
                            <img src={clogo} alt='' className='mx-auto'/>
                            <p className='text-[13px] text-[#333333]'>CIGNAL</p>
                            <p className='text-[13px] text-[#333333]'>Below is the detail of your transaction. You may print this for your reference</p>
                            <div className="flex justify-between border-y-4 border-[#333333] py-2">
                              <p className='text-[13px] text-[#333333]'>Transaction Date:</p>
                              <p className='text-[13px] text-[#333333]'>2024-01-29 20:30:52</p>
                            </div>
                            <div className="flex justify-between py-1">
                              <p className='text-[13px] text-[#333333]'>Account Name:</p>
                              <p className='text-[13px] text-[#333333]'>Claudia Cubos</p>
                            </div>
                            <div className="flex justify-between py-1">
                              <p className='text-[13px] text-[#333333]'>Account No:</p>
                              <p className='text-[13px] text-[#333333]'>9020897839</p>
                            </div>
                            <div className="flex justify-between py-1">
                              <p className='text-[13px] text-[#333333]'>Biller Name:</p>
                              <p className='text-[13px] text-[#333333]'>CIGNAL</p>
                            </div>
                            <div className="flex justify-between py-1">
                              <p className='text-[13px] text-[#333333]'>Trace Code:</p>
                              <div className="text-right">
                                <p className='text-[13px] text-[#333333]'>SUCCESS! REF</p>
                                <p className='text-[13px] text-[#333333]'>#EE83BFDA0766</p>
                              </div>
                            </div>
                            <div className="flex justify-between border-y-4 border-[#333333] py-2">
                              <p className='text-[13px] text-[#333333] font-black'>*** Total Amount</p>
                              <p className='text-[13px] text-[#333333] font-black'>PHP 800 ***</p>
                            </div>
                            <div className="flex justify-between items-center gap-2 py-2">
                              <img src={dots} alt='' className='h-1.5 w-[5vw]'/>
                              <p className='text-[13px] text-[#333333] font-semibold'>Service Center Details</p>
                              <img src={dots} alt='' className='h-1.5 w-[5vw]'/>
                            </div>
                            <div className="flex justify-between py-1">
                              <p className='text-[13px] text-[#333333]'>Branch Name:</p>
                              <p className='text-[13px] text-[#333333]'></p>
                            </div>
                            <div className="flex justify-between py-1">
                              <p className='text-[13px] text-[#333333]'>Account_ID:</p>
                              <p className='text-[13px] text-[#333333]'>5322785/191342</p>
                            </div>
                            <div className="flex justify-between py-1">
                              <p className='text-[13px] text-[#333333]'>Address:</p>
                              <p className='text-[13px] text-[#333333]'></p>
                            </div>
                              <div className="flex justify-between pt-1 pb-2 border-b border-[#EEEEEE]">
                              <p className='text-[13px] text-[#333333]'>Phone_No:</p>
                              <p className='text-[13px] text-[#333333]'></p>
                            </div>
                            <button className='bg-[#125CA5] p-2 rounded-full w-full text-white my-4'>Print Report</button>
                      </div>
                    </div>
                  )}

              </div>
              <div className="bg-white rounded-lg p-4">
                <h1 className='text-[18px] lg:text-[20px] text-[#09090B] font-bold mb-2'>Money Transfer Transactions</h1>
                <div className="overflow-x-auto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Target #</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Mobile #</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Amount</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Charge</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Date</th>
                                <th className='text-[#0F172A] text-[16px] font-bold'>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {moneyTransaction.map((data, index) => (
                            <tr key={index}>
                                <td>{data.target}</td>
                                <td>{data.mobile}</td>
                                <td className='text-[#05CD99] font-medium'>{data.amount}</td>
                                <td className='text-[#DC2626] font-medium'>{data.charge}</td>
                                <td>{data.date}</td>
                                <td>{data.remarks}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-full lg:w-1/3">
          <h1 className='text-[#101010] text-[30px] font-bold mb-4'>Payment form</h1>
          <div className="bg-white rounded-lg p-8">
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 '>Search Biller</p>
              <input type="text" placeholder="Type Biller Name" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
            </div>
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 mt-4 '>Biller Category</p>
              <select className="select select-bordered select-sm w-full max-w-xs rounded-xl text-[#334155] font-bold">
                <option disabled selected>Choose Category</option>
                <option>Category 1</option>
                <option>Category 2</option>
                <option>Category 3</option>
              </select>
            </div>
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 mt-4 '>Biller Name</p>
              <select className="select select-bordered select-sm w-full max-w-xs rounded-xl text-[#334155] font-bold">
                <option disabled selected>Choose Biller</option>
                <option>Biller 1</option>
                <option>Biller 2</option>
                <option>Biller 3</option>
              </select>
            </div>
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 mt-4 '>Subscriber Name</p>
              <input type="text" placeholder="Subscriber Name" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
            </div>
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 mt-4 '>Billing Address</p>
              <input type="text" placeholder="Billing Address" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
            </div>
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 mt-4 '>Contact Number</p>
              <input type="text" placeholder="Contact Number" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
            </div>
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 mt-4 '>Email Address</p>
              <input type="text" placeholder="Email Address" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
            </div>
            <div className='w-full'>
              <p className='text-[#09090B] text-[14px] font-semibold mb-1 mt-4 '>Amount</p>
              <input type="text" placeholder="100" class="input input-bordered input-sm w-full max-w-xs rounded-xl text-[#334155] font-bold" />
            </div>
            <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold'>Purchase</button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Bills