import React, { useState, useEffect } from 'react';
import logoblack from '../assets/img/landingpage/logoblack.webp';
// import logowhite  from '../assets/img/landingpage/logowhite.webp';
import Hero from './Hero';
import Everything from './Everything';
import WhyChoose from './WhyChoose';
import Personalize from './Personalize';
import Features from './Features';
import GettingStarted from './GettingStarted';
import StayUpdate from './StayUpdate';
import Footer from './Footer';
// import Pricing from './Pricing';
// import Customers from './Customers';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  // const [logoSrc, setLogoSrc] = useState(logoblack);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
      // Change logo based on scroll position
      // if (position > 0) {
      //   setLogoSrc(logowhite);
      // } else {
      //   setLogoSrc(logoblack);
      // }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  console.log(scrollPosition);


  return (
    <>
      <div className="sticky top-0 z-50 navbar navbar-default" id="navbar">
        <div className="px-4 lg:px-40 py-2">
          <div className="flex justify-between items-center font-pjs bg-white rounded-full p-3 px-8">
            <img src={logoblack} alt="logo" className="w-[30%] lg:w-[12%]" />
            <div className="flex gap-2 items-center">
              <button id="menu-icon" className="block lg:hidden text-[#3483F7] font-semibold" onClick={toggleMenu} >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              </button>
              <div
                className={`${
                  isMenuOpen 
                  ? 'blocked fixed top-0 left-0 w-full h-full bg-gradient-to-b from-[#68CCFF] to-[#3483F7] z-50 overflow-auto p-8'
                  : 'hidden'
                } lg:flex justify-between gap-14 items-center`}
              >
                <button className="block lg:hidden text-white absolute top-4 right-4" onClick={toggleMenu} >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
                <h1 className="text-[#353F4F] font-semibold py-4 hover:text-[#353F4F]"><a href="#about" onClick={closeMenu}>About</a></h1>
                <h1 className="text-[#353F4F] font-semibold py-4 hover:text-[#353F4F]"><a href="#services" onClick={closeMenu}>Services</a></h1>
                <h1 className="text-[#353F4F] font-semibold py-4 hover:text-[#353F4F]"><a href="#features" onClick={closeMenu}>Features</a></h1>
                <h1 className="text-[#353F4F] font-semibold py-4 hover:text-[#353F4F]"><a href="#contact" onClick={closeMenu}>Contact Us</a></h1>
                <h1 className="text-[#353F4F] font-semibold py-4 hover:text-[#353F4F]"><a href="https://netclixsco.com/login.php" onClick={closeMenu}>Sign in</a></h1> {/* change to /sign-in */}
                <a href="#" onClick={closeMenu}><button className="bg-[#1F89FA] font-semibold text-white rounded-full py-2 px-4 hover:text-white hover:bg-[#353F4F]">Sign up</button></a> {/* change to /sign-up */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Hero />
      <Everything id="about"/>
      <Personalize/>
      <WhyChoose id="services"/>
      <Features id="features"/>
      {/* <Pricing /> */}
      {/* <Customers/> */}
      <GettingStarted id="contact"/>
      <StayUpdate/>
      <Footer />
    </>
  );
};

export default Navbar;
