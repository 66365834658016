import React, { useState } from 'react';
import profile1 from "../assets/img/dashboard/profile1.png"
import commission from "../assets/img/dashboard/commission.svg"
import encashment from "../assets/img/dashboard/encashment.svg"
import remaining from "../assets/img/dashboard/remaining.svg"
import referral from "../assets/img/dashboard/referral.svg"
import points from "../assets/img/dashboard/points.svg"
import check from "../assets/img/dashboard/check.svg"
import clock from "../assets/img/dashboard/clock.svg"
import avatar from "../assets/img/dashboard/avatar.png"
import cloud from "../assets/img/dashboard/cloud.svg"
import close from "../assets/img/dashboard/close.svg"

const member = [
    {
        id: 3012,
        name: 'Sam Smith',
        role: 'Dealer',
        enrollment: 'Jan 19 , 2014 - 11:06 AM',
        members: '0',
    },
    {
        id: 3013,
        name: 'Michael Simons',
        role: 'Retailer',
        enrollment: 'Jan 19 , 2014 - 11:06 AM',
        members: '1',
    },
    {
        id: 3014,
        name: 'Patrick Star',
        role: 'Dealer',
        enrollment: 'Jan 19 , 2014 - 11:06 AM',
        members: '2',
    },
    {
        id: 3015,
        name: 'John Doe',
        role: 'Retailer',
        enrollment: 'Jan 19 , 2014 - 11:06 AM',
        members: '1',
    },
    {
        id: 3016,
        name: 'Bill Smith',
        role: 'Retailer',
        enrollment: 'Jan 19 , 2014 - 11:06 AM',
        members: '1',
    },
];
const codes = [
    {
        code: 'A2CCCKHFTB64431',
        status: 'Activated',
        role: 'Dealer',
        enrollment: 'Jan 19 , 2014',
        account: '6054283',
    },
    {
        code: 'AMYMBWEHQ2644',
        status: 'Activated',
        role: 'Retailer',
        enrollment: 'Jan 19 , 2014',
        account: '9828571',
    },
    {
        code: 'A634UJT9SA64431',
        status: 'Pending',
        role: 'Dealer',
        enrollment: 'Jan 19 , 2014',
        account: '',
    },
    {
        code: 'AXS5EE3TX928536',
        status: 'Pending',
        role: 'Retailer',
        enrollment: 'Jan 19 , 2014',
        account: '',
    },
    {
        code: 'AWF3JG6DV42856',
        status: 'Pending',
        role: 'Retailer',
        enrollment: 'Jan 19 , 2014',
        account: '',
    },
];  
const transaction = [
    {
        img: avatar,
        name: 'John Doe',
        transaction: 'Online Loading',
        amount: '+100.00 PHP',
    },
    {
        img: avatar,
        name: 'Eva Mendes',
        transaction: 'Air & Hotel',
        amount: '+100.00 PHP',
    },
    {
        img: avatar,
        name: 'Eva Mendes',
        transaction: 'Cash Transfer',
        amount: '+100.00 PHP',
    },
    {
        img: avatar,
        name: 'Eva Mendes',
        transaction: 'Bills Payment',
        amount: '+100.00 PHP',
    },
];

const Dashboard = () => {
    const [activeBalance, setActiveBalance] = useState(true);
    const [activeTable, setActiveTable] = useState(true);
    const [activeTab, setActiveTab] = useState('All');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const filteredTransactions = activeTab === 'All' ? transaction : transaction.filter(data => data.transaction === activeTab);

    const handleBalance = () => {
        setActiveBalance((prevActive) => !prevActive);
    };
    const handleTable = () => {
        setActiveTable((prevActive) => !prevActive);
    };
    const handleClick = (tab) => {
        setActiveTab(tab);
    };
    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };
    const toggleWithdraw = () => {
        setIsWithdrawOpen(prevState => !prevState);
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };
    const handleFileRemove = () => {
        setSelectedFile(null);
    };
    
    return (
        <div className="bg-[#E7EEF8]">
            <div className="flex flex-col lg:flex-row justify-between gap-4 mb-4">
                <div className="bg-user rounded-2xl text-center p-12">
                    <img
                        src={profile1}
                        alt=""
                        className="w-24 h-24 mx-auto mt-20 mb-4 cursor-pointer"
                        onClick={toggleModal}
                    />
                    {isModalOpen && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="bg-white p-6 rounded-lg">
                                {selectedFile ? (
                                    <>
                                        <div className="bg-[#F1F5F9] rounded-lg mb-4">
                                            <div className="flex justify-end">
                                                <img src={close} alt='' className='cursor-pointer pt-3 pr-3' onClick={toggleModal} />
                                            </div>
                                            <div className="p-8">
                                                <img src={cloud} alt='' className='mx-auto' />
                                                <h2 className="text-[24px] font-semibold mb-4">Uploaded picture</h2>
                                                <p className='text-[#292D32] text-[18px]'><span className='font-bold'>Selected file:</span> <span className='italic'>{selectedFile.name}</span></p>
                                            </div>
                                        </div>
                                        <button className="text-white bg-red-500 rounded-lg px-8 py-2 mt-4" onClick={handleFileRemove}>Remove</button>
                                    </>
                                ) : (
                                    <>
                                        <div className="bg-[#F1F5F9] rounded-lg mb-4">
                                            <div className="flex justify-end">
                                                <img src={close} alt='' className='cursor-pointer pt-3 pr-3' onClick={toggleModal} />
                                            </div>
                                            <div className="p-8">
                                                <img src={cloud} alt='' className='mx-auto' />
                                                <h2 className="text-[24px] font-semibold mb-4">Upload a picture</h2>
                                                <div>
                                                    <p className='text-[#292D32] text-[18px]'>Choose a file or drag & drop it here</p>
                                                    <p className='text-[#A9ACB4] text-[14px]'>JPEG, PNG, PDG, and MP4 formats, up to 50MB</p>
                                                    <input type="file" onChange={handleFileChange} className="hidden" />
                                                </div>
                                            </div>
                                        </div>
                                        <button className="text-white bg-blue-500 rounded-lg px-8 py-2" onClick={() => document.querySelector('input[type="file"]').click()}> Upload </button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <h2 className='text-[20px] text-[#09090B] font-bold'>John Doe</h2>
                    <p className='text-[14px] text-[#71717A] mb-4'>5322785</p>
                    <p className='text-[#2558ED] font-semibold bg-[#D3DDF5] rounded-lg w-1/2 mx-auto mb-8'>Dealer</p>
                    <button className='text-white font-semibold bg-[#006FEE] rounded-lg py-2 px-12'>Edit Profile</button>
                </div>
                <div className="bg-white rounded-2xl p-8 flex-grow">
                    <h2 className='text-[20px] text-[#09090B] font-bold'>Reward Collection</h2>
                    <div className="bg-[#F1F5F9] rounded-full p-2 mb-2">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-4">
                                <img src={commission} alt='' />
                                <h4 className='text-[14px] text-[#09090B] font-semibold'>Total Commission</h4>
                            </div>
                            <h4 className='text-[20px] text-[#09090B] font-semibold pr-4'>5</h4>
                        </div>
                    </div>
                    <div className="bg-[#F1F5F9] rounded-full p-2 mb-2">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-4">
                                <img src={encashment} alt='' />
                                <h4 className='text-[14px] text-[#09090B] font-semibold'>Total Encashment</h4>
                            </div>
                            <h4 className='text-[20px] text-[#09090B] font-semibold pr-4'>5</h4>
                        </div>
                    </div>
                    <div className="bg-[#F1F5F9] rounded-full p-2 mb-2">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-4">
                                <img src={remaining} alt='' />
                                <h4 className='text-[14px] text-[#09090B] font-semibold'>Remaining Commission</h4>
                            </div>
                            <h4 className='text-[20px] text-[#09090B] font-semibold pr-4'>5</h4>
                        </div>
                    </div>
                    <div className="bg-[#F1F5F9] rounded-full p-2 mb-2">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-4">
                                <img src={referral} alt='' />
                                <h4 className='text-[14px] text-[#09090B] font-semibold'>Bonus Referral</h4>
                            </div>
                            <h4 className='text-[20px] text-[#09090B] font-semibold pr-4'>5</h4>
                        </div>
                    </div>
                    <div className="bg-[#F1F5F9] rounded-full p-2 mb-2">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-4">
                                <img src={points} alt='' />
                                <h4 className='text-[14px] text-[#09090B] font-semibold'>DRD Code Points</h4>
                            </div>
                            <h4 className='text-[20px] text-[#09090B] font-semibold pr-4'>5</h4>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-2xl p-8 flex-grow">
                    <h2 className='text-[20px] text-[#09090B] font-bold'>Withdrawable Balance</h2>
                    <div className="flex justify-between gap-1 bg-[#F1F5F9] p-2 rounded-full w-full mt-4">
                      <button
                        className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl ${
                          activeBalance
                            ? "bg-[#FFFFFF] text-[#09090B] w-full"
                            : "bg-transparent w-full"
                        }`}
                        onClick={handleBalance}
                      >
                        Commission
                      </button>
                      <button
                        className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl ${
                          activeBalance
                            ? "bg-transparent w-full"
                            : "bg-[#FFFFFF] text-[#09090B] w-full"
                        }`}
                        onClick={handleBalance}
                      >
                        Bonus
                      </button>
                    </div>
                    {activeBalance ? (
                        <div>
                            <div className='bg-cover rounded-3xl p-8 my-4 mb-8'>
                                <p className='text-[14px] text-white mb-8'>Commission balance</p>
                                <h1 className='text-[40px] lg:text-[48px] text-white font-bold'>₱ 1200.00</h1>
                            </div>
                            <button className='text-white font-semibold bg-[#006FEE] rounded-lg p-2 w-full' onClick={toggleWithdraw}>Withdraw</button>
                            {isWithdrawOpen && (
                                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                    <div className="bg-white p-6 rounded-lg text-center">
                                        <div className="bg-[#F1F5F9] rounded-lg mb-4">
                                            <div className="flex justify-end">
                                                <img src={close} alt='' className='cursor-pointer pt-3 pr-3' onClick={toggleWithdraw} />
                                            </div>
                                            <div className="py-4 px-16">
                                                <h2 className="text-[24px] font-semibold mb-2">Withdraw</h2>
                                                <p className='text-[#71717A] text-[14px] mb-8'>$1200.22 AVAILABLE</p>
                                                <p className='text-[#036AF5] text-[48px] font-bold'>$1200.22</p>
                                            </div>
                                        </div>
                                        <button className="text-white bg-blue-500 rounded-lg px-8 py-2"> Withdraw </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className='bg-cover rounded-3xl p-8 my-4 mb-8'>
                                <p className='text-[14px] text-white mb-8'>Team builder bonus</p>
                                <h1 className='text-[40px] lg:text-[48px] text-white font-bold'>₱ 1200.00</h1>
                            </div>
                            <button className='text-white font-semibold bg-[#006FEE] rounded-lg p-2 w-full' onClick={toggleWithdraw}>Withdraw</button>
                            {isWithdrawOpen && (
                                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                    <div className="bg-white p-6 rounded-lg text-center">
                                        <div className="bg-[#F1F5F9] rounded-lg mb-4">
                                            <div className="flex justify-end">
                                                <img src={close} alt='' className='cursor-pointer pt-3 pr-3' onClick={toggleWithdraw} />
                                            </div>
                                            <div className="py-4 px-16">
                                                <h2 className="text-[24px] font-semibold mb-2">Withdraw</h2>
                                                <p className='text-[#71717A] text-[14px] mb-8'>$1200.22 AVAILABLE</p>
                                                <p className='text-[#036AF5] text-[48px] font-bold'>$1200.22</p>
                                            </div>
                                        </div>
                                        <button className="text-white bg-blue-500 rounded-lg px-8 py-2"> Withdraw </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between gap-4">
                <div className="bg-white rounded-2xl p-6 lg:p-8 flex-grow">
                    <div className="flex gap-1 bg-[#F1F5F9] p-2 rounded-full w-full lg:w-1/3 mb-4">
                        <button
                            className={`text-[#334155] font-arabicmed text-[12px] py-2 px-5 rounded-3xl ${
                                activeTable
                                ? "bg-[#FFFFFF] text-[#09090B] w-full"
                                : "bg-transparent w-full"
                            }`}
                            onClick={handleTable}
                        >
                        Members
                        </button>
                        <button
                        className={`text-[#334155] font-arabicmed text-[12px] py-2 px-5 rounded-3xl ${
                            activeTable
                            ? "bg-transparent w-full"
                            : "bg-[#FFFFFF] text-[#09090B] w-full"
                        }`}
                        onClick={handleTable}
                        >
                        Codes
                        </button>
                    </div>
                    {activeTable ? (
                        <div className="overflow-x-auto">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>ID</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Name</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Role</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Enrollment</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Members</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {member.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.id}</td>
                                        <td>{data.name}</td>
                                        <td>{data.role}</td>
                                        <td>{data.enrollment}</td>
                                        <td>{data.members}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Code</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Name</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Role</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Enrollment</th>
                                        <th className='text-[#0F172A] text-[16px] font-bold'>Account</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {codes.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.code}</td>
                                            <td>
                                                {data.status === 'Activated' ? (
                                                    <div className="bg-[#05CD99] text-[12px] text-white rounded-full p-1 flex items-center gap-1 w-full">
                                                        <img src={check} alt='' className='h-3 w-3'/> Activated
                                                    </div>
                                                ) : (
                                                    <div className="bg-[#A3ADB8] text-[12px] text-white rounded-full p-1 flex items-center gap-1 w-full">
                                                        <img src={clock} alt='' className='h-3 w-3'/>Pending
                                                    </div>
                                                )}
                                            </td>
                                            <td>{data.role}</td>
                                            <td>{data.enrollment}</td>
                                            <td className='text-[#006FEE]'>{data.account}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className="bg-white rounded-2xl p-6 lg:p-8 flex-grow">
                    <div className="flex justify-between items-center">
                        <h2 className='text-[20px] text-[#09090B] font-bold'>Last Transaction</h2>
                        <p className='text-[14px] text-[#71717A]'>See all</p>
                    </div>
                    <div role="tablist" className="tabs mt-4 gap-2 overflow-x-auto">
                        <button
                            role="tab"
                            className={`tab text-[12px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                                activeTab === 'All' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                            }`}
                            onClick={() => handleClick('All')}
                        >
                        All
                        </button>
                        <button
                            role="tab"
                            className={`tab text-[12px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                                activeTab === 'Online Loading' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                            }`}
                            onClick={() => handleClick('Online Loading')}
                        >
                        Online Loading
                        </button>
                        <button
                            role="tab"
                            className={`tab text-[12px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                                activeTab === 'Air & Hotel' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                            }`}
                            onClick={() => handleClick('Air & Hotel')}
                        >
                        Air & Hotel
                        </button>
                        <button
                            role="tab"
                            className={`tab text-[12px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                                activeTab === 'Cash Transfer' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                            }`}
                            onClick={() => handleClick('Cash Transfer')}
                        >
                        Cash Transfer
                        </button>
                        <button
                            role="tab"
                            className={`tab text-[12px] border border-[#E2E8F0] rounded-3xl px-2 whitespace-nowrap ${
                                activeTab === 'Bills Payment' ? 'bg-blue-500 text-white' : 'text-[#71717A]'
                            }`}
                            onClick={() => handleClick('Bills Payment')}
                        >
                        Bills Payment
                        </button>
                    </div>
                    {filteredTransactions.map((data, index) => (
                        <div key={index}>
                        <div className="flex justify-between items-center border-b border-[#E2E8F0] p-2 lg:p-4 my-2">
                            <div className="flex gap-4">
                                <img src={data.img} className='h-8 w-8' alt="" />
                                <div>
                                    <h1 className="text-[16px] text-[#09090B]">{data.name}</h1>
                                    <p className="text-[14px] text-[#71717A]">{data.transaction}</p>
                                </div>
                            </div>
                            <h1 className="text-[#05CD99] text-[12px] lg:text-[16px] font-bold">{data.amount}</h1>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Dashboard