import React from 'react'
import { useNavigate } from 'react-router-dom';
import back from '../assets/img/dashboard/back.svg'

const RequestConfirmation = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); 
    };


    return (
        <div>
            <img src={back} alt='' className='mb-4 cursor-pointer' onClick={handleBackClick}/>
            <div className="flex justify-center ">
                <div className="bg-white rounded-2xl p-8 w-full lg:w-1/3 h-1/2">
                    <h2 className='text-[18px] text-[#334155] font-bold text-center mb-4'>We sent you an email!</h2>
                    <p className='text-[14px] text-[#09090B] font-bold text-center mb-4'>An Instruction from Dragonpay has been sent to your registered email @ info@netclixs.net</p>

                    <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold'>Close</button>
                </div>
            </div>
        </div>
    )
}

export default RequestConfirmation