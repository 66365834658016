import React, { useState } from 'react';
import airline from '../assets/img/dashboard/airline_bg.webp'
import airplaneW from '../assets/img/dashboard/airplaneW.svg'
import airplaneB from '../assets/img/dashboard/airplaneB.svg'
import hotelW from '../assets/img/dashboard/hotelW.svg'
import hotelB from '../assets/img/dashboard/hotelB.svg'
import switcharrow from '../assets/img/dashboard/switcharrow.svg'
import search from '../assets/img/dashboard/search.svg'
import sg from '../assets/img/dashboard/sg.webp'
import jp from '../assets/img/dashboard/jp.webp'
import gm from '../assets/img/dashboard/gm.webp'
import pr from '../assets/img/dashboard/pr.webp'
import plane from '../assets/img/dashboard/plane.svg'

const Airline = () => {
  const [activeTab, setActiveTab] = useState('flight');
  const [showSg, setshowSg] = useState(false);
  const [showJp, setshowJp] = useState(false);
  const [showGm, setshowGm] = useState(false);
  const [showPr, setshowPr] = useState(false);
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <img src={airline} alt='' className='w-[100vw]'/>

      <div className="px-0 lg:px-8">
        <div className="flex mt-5 lg:-mt-32">
          <div className="flex bg-white rounded-t-3xl p-4">
            <button
              className={`tab-btn flex p-2 rounded-lg font-semibold gap-1 items-center ${activeTab === 'flight' ? 'active bg-[#2558ED] text-white' : 'bg-[#F1F5F9] text-[#2558ED]'}`}
              onClick={() => handleTabChange('flight')}
            >
              {activeTab === 'flight' ? <img src={airplaneW} alt="Flight" className='h-6 w-6' /> : <img src={airplaneB} alt="Flight" className='h-6 w-6' />}
              <span>Flight</span>
            </button>
            <button
              className={`tab-btn flex p-2 rounded-lg font-semibold gap-1 items-center ${activeTab === 'hotel' ? 'active bg-[#2558ED] text-white' : 'bg-[#F1F5F9] text-[#2558ED]'}`}
              onClick={() => handleTabChange('hotel')}
            >
              {activeTab === 'hotel' ? <img src={hotelW} alt="Hotel" className='h-6 w-6' /> : <img src={hotelB} alt="Hotel" className='h-6 w-6' />}
              <span>Hotel</span>
            </button>
          </div>
        </div>
        <div className="flex mb-20">
          <div className="bg-white rounded-b-3xl rounded-tr-3xl p-4 shadow-lg w-full">
              {activeTab === 'flight' && (
                <>
                  <div className="flex flex-col lg:flex-row items-center gap-4 mb-4">
                    <div className="flex">
                      <p className='text-[#09090B] font-bold text-[16px] whitespace-nowrap'>One way</p>
                      <input type="radio" name="radio-1" className="radio ml-2 mr-6" />
                      <p className='text-[#09090B] font-bold text-[16px] whitespace-nowrap'>Round trip</p>
                      <input type="radio" name="radio-1" className="radio ml-2 mr-6" checked />
                    </div>
                    <select className="select select-ghost max-w-xs font-bold text-[16px] text-[#09090B] w-full lg:w-1/8">
                      <option>01 Passengers</option>
                      <option>02 Pilot</option>
                      <option>02 FLight Attendant</option>
                    </select>
                    <select className="select select-ghost max-w-xs font-bold text-[16px] text-[#09090B] w-full lg:w-1/8">
                      <option>Economy Class</option>
                      <option>Premium Economy Class</option>
                      <option>Business Class</option>
                      <option>First Class</option>
                    </select>
                  </div>
                  <div className="flex flex-col lg:flex-row justify-between gap-2 items-center mb-4">
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>FROM</p>
                      <input type="text" className="grow text-[18px] mt-4 -ml-8 font-bold text-[#323232]" value="CEBU (CEB)" />
                    </label>
                    <button className='bg-[#2558ED] p-2 rounded-full lg:w-full hover:bg-[#323232]'>
                      <img src={switcharrow} alt='' />
                    </button>
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>TO</p>
                      <input type="text" className="grow text-[18px] mt-4 -ml-4 font-bold text-[#323232]" value="Manila (MNL)" />
                    </label>
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>DEPARTURE</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-16 font-bold text-[#323232]" />
                    </label>
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>RETURN</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-12 font-bold text-[#323232]" />
                    </label>
                    {/* <label className="input input-bordered flex text-[12px] gtext-left">
                      <p className='font-semibold text-[#323232]'>DEPARTURE</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-16 font-bold text-[#323232]" />
                      <p className='font-semibold text-[#323232] ml-8'>RETURN</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-12 font-bold text-[#323232]" />
                    </label> */}
                    <button className='bg-[#2558ED] p-4 rounded-full lg:w-full hover:bg-[#323232]'>
                      <img src={search} alt='' className='w-6 h-6'/>
                    </button>
                  </div>
                </>
              )}
              {activeTab === 'hotel' && (
                <>
                  <div className="flex flex-col lg:flex-row items-center gap-4 mb-4">
                    <div className="flex">
                      <p className='text-[#09090B] font-bold text-[16px] whitespace-nowrap'>One way</p>
                      <input type="radio" name="radio-1" className="radio ml-2 mr-6" />
                      <p className='text-[#09090B] font-bold text-[16px] whitespace-nowrap'>Round trip</p>
                      <input type="radio" name="radio-1" className="radio ml-2 mr-6" checked />
                    </div>
                    <select className="select select-ghost max-w-xs font-bold text-[16px] text-[#09090B] w-full lg:w-1/8">
                      <option>01 Passengers</option>
                      <option>02 Pilot</option>
                      <option>02 FLight Attendant</option>
                    </select>
                    <select className="select select-ghost max-w-xs font-bold text-[16px] text-[#09090B] w-full lg:w-1/8">
                      <option>Economy Class</option>
                      <option>Premium Economy Class</option>
                      <option>Business Class</option>
                      <option>First Class</option>
                    </select>
                  </div>
                  <div className="flex flex-col lg:flex-row justify-between gap-2 items-center mb-4">
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>FROM</p>
                      <input type="text" className="grow text-[18px] mt-4 -ml-8 font-bold text-[#323232]" value="CEBU (CEB)" />
                    </label>
                    <button className='bg-[#2558ED] p-2 rounded-full lg:w-full hover:bg-[#323232]'>
                      <img src={switcharrow} alt='' />
                    </button>
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>TO</p>
                      <input type="text" className="grow text-[18px] mt-4 -ml-4 font-bold text-[#323232]" value="Manila (MNL)" />
                    </label>
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>DEPARTURE</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-16 font-bold text-[#323232]" />
                    </label>
                    <label className="input input-bordered flex text-[12px] text-left w-full">
                      <p className='font-semibold text-[#323232]'>RETURN</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-12 font-bold text-[#323232]" />
                    </label>
                    {/* <label className="input input-bordered flex text-[12px] gtext-left">
                      <p className='font-semibold text-[#323232]'>DEPARTURE</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-16 font-bold text-[#323232]" />
                      <p className='font-semibold text-[#323232] ml-8'>RETURN</p>
                      <input type="date" className="grow text-[18px] mt-4 -ml-12 font-bold text-[#323232]" />
                    </label> */}
                    <button className='bg-[#2558ED] p-4 rounded-full lg:w-full hover:bg-[#323232]'>
                      <img src={search} alt='' className='w-6 h-6'/>
                    </button>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      
      <div className='px-0 lg:px-8'>
        <div className="flex justify-between mb-8">
          <h1 className='text=[#09090B] text-[20px] font-bold'>Popular Destination</h1>
          <h1 className='text=[#09090B] text-[20px] font-bold underline'>Explore All</h1>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-8">
          <div className="relative">
            <img src={sg} alt='' className='rounded-3xl w-full' onMouseEnter={() => setshowSg(true)} onMouseLeave={() => setshowSg(false)}/>
            {showSg && (
              <div className="absolute top-0 left-0 text-white p-8 rounded-md">
                <p className='text-[20px] font-bold'>Singapore</p>
                <p className='text-[14px] font-medium mb-4'>from ₱2,099  </p>
                <img src={plane} alt=''/>
              </div>
            )}
          </div>
          <div className="relative">
            <img src={jp} alt='' className='rounded-3xl w-full' onMouseEnter={() => setshowJp(true)} onMouseLeave={() => setshowJp(false)}/>
            {showJp && (
              <div className="absolute top-0 left-0 text-white p-8 rounded-md">
                <p className='text-[20px] font-bold'>Japan</p>
                <p className='text-[14px] font-medium mb-4'>from ₱2,099  </p>
                <img src={plane} alt=''/>
              </div>
            )}
          </div>
          <div className="relative">
            <img src={gm} alt='' className='rounded-3xl w-full' onMouseEnter={() => setshowGm(true)} onMouseLeave={() => setshowGm(false)}/>
            {showGm && (
              <div className="absolute top-0 left-0 text-white p-8 rounded-md">
                <p className='text-[20px] font-bold'>Germany</p>
                <p className='text-[14px] font-medium mb-4'>from ₱2,099  </p>
                <img src={plane} alt=''/>
              </div>
            )}
          </div>
          <div className="relative">
            <img src={pr} alt='' className='rounded-3xl w-full' onMouseEnter={() => setshowPr(true)} onMouseLeave={() => setshowPr(false)}/>
            {showPr && (
              <div className="absolute top-0 left-0 text-white p-8 rounded-md">
                <p className='text-[20px] font-bold'>Paris</p>
                <p className='text-[14px] font-medium mb-4'>from ₱2,099  </p>
                <img src={plane} alt=''/>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Airline