import React from 'react'
import { Routes, Route } from "react-router-dom";
import Sidebar from "./shared/Sidebar"
import Topbar from "./shared/Topbar"
import Dashboard from './dashboard/Dashboard';
import Home from './dashboard/Home';
import Airline from './dashboard/Airline';
import Online from './dashboard/Online';
import Bills from './dashboard/Bills';
import Cash from './dashboard/Cash';
import Request from './dashboard/Request';
import Profile from './dashboard/Profile';
import CashTransfer from './dashboard/CashTransfer';
import CashEwalletConfirmation from './dashboard/CashEwalletConfirmation';
import CashBankConfirmation from './dashboard/CashBankConfirmation';
import RequestConfirmation from './dashboard/RequestConfirmation';
import Marketing from './dashboard/Marketing';
import ProfileBank from './dashboard/ProfileBank';

function App() {
  
  return (
      <div className="bg-[#E7EEF8] min-h-screen font-dm"> 
        <Sidebar />
        <div className="p-4 sm:ml-64">
            <Topbar />
            <div className="px-4 lg:px-8">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/home" element={<Home />} />
                <Route path="/airline" element={<Airline />} />
                <Route path="/online" element={<Online />} />
                <Route path="/bills" element={<Bills />} />
                <Route path="/cash" element={<Cash />} />
                <Route path="/cash/transfer" element={<CashTransfer />} />
                <Route path="/cash/e-wallet/confirmation" element={<CashEwalletConfirmation />} />
                <Route path="/cash/bank/confirmation" element={<CashBankConfirmation />} />
                <Route path="/request" element={<Request />} />
                <Route path="/request/confirmation" element={<RequestConfirmation />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/bank" element={<ProfileBank />} />
                <Route path="/marketing-tools" element={<Marketing />} />
              </Routes>
            </div>
        </div>
      </div>
  );
}

export default App;
