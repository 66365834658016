import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../assets/img/dashboard/back.svg'

const CashTransfer = () => {
    const [activeTab, setactiveTab] = useState(true);
    const navigate = useNavigate();

    const handleLoad = () => {
      setactiveTab((prevActive) => !prevActive);
    };
    const handleEwalletClick = () => {
        navigate('/cash/e-wallet/confirmation');
    };
    const handleBankClick = () => {
        navigate('/cash/bank/confirmation');
    };
    const handleBackClick = () => {
        navigate(-1); 
    };

    return (
        <div>
            <img src={back} alt='' className='mb-4 cursor-pointer' onClick={handleBackClick}/>
            <div className="flex justify-center ">
                <div className="bg-white rounded-2xl p-8 w-full lg:w-1/3 h-1/2">
                    <div className="bg-gradient-to-b from-[#19ACED] to-[#0E32CF] rounded-2xl p-4 text-center">
                    <p className='text-white text-[14px]'>Available Funds</p>
                    <h1 className='text-white text-[32px] font-bold mb-4'>₱ 1200.00</h1>
                    <div className="flex justify-between">
                        <p className='text-white text-[14px]'>Available Balance</p>
                        <h6 className='text-white text-[14px] font-bold'>₱ 917.26</h6>
                    </div>
                    </div>
                    <div className="flex gap-1 bg-[#F1F5F9] p-2 rounded-full w-full my-4">
                        <button
                            className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl ${
                                activeTab
                                ? "bg-[#FFFFFF] text-[#09090B] w-full font-semibold"
                                : "bg-transparent text-[#334155] w-full"
                            }`}
                            onClick={handleLoad}
                        >
                        E-Wallet
                        </button>
                        <button
                        className={`text-[#334155] font-arabicmed text-[12px] py-1 px-5 rounded-3xl ${
                            activeTab
                            ? "bg-transparent text-[#334155] w-full"
                            : "bg-[#FFFFFF] text-[#09090B] w-full font-semibold"
                        }`}
                        onClick={handleLoad}
                        >
                        Bank
                        </button>
                    </div>
                    {activeTab ? (
                        <div>
                            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Operator</p>
                            <select className="select select-bordered select-sm w-full max-w-xs mb-4 rounded-xl text-[#334155] font-bold">
                                <option selected>Gcash</option>
                                <option>Paymaya</option>
                                <option>Paypal</option>
                            </select>

                            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Account Name</p>
                            <input type="text" placeholder="Loven Judge" className="input input-sm w-full max-w-xs mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />
                            
                            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Mobile Number</p>
                            <input type="text" placeholder="09123456789" className="input input-sm w-full max-w-xs mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />

                            <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold' onClick={handleEwalletClick}>Submit</button>
                        </div>
                    ) : (
                        <div>
                            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Choose Bank</p>
                            <select className="select select-bordered select-sm w-full max-w-xs mb-4 rounded-xl text-[#334155] font-bold">
                                <option selected>Unionbank </option>
                                <option>BPI</option>
                                <option>BDO</option>
                            </select>

                            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Account Name</p>
                            <input type="text" placeholder="Loven Judge" className="input input-sm w-full max-w-xs mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />
                            
                            <p className='text-[#09090B] text-[12px] font-medium mb-1'>Mobile Number</p>
                            <input type="text" placeholder="09123456789" className="input input-sm w-full max-w-xs mb-4 rounded-xl bg-[#F1F5F9] text-[#334155] font-bold" />

                            <button className='bg-[#006EF0] text-white w-full mt-6 py-2 rounded-2xl font-semibold' onClick={handleBankClick}>Submit</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CashTransfer