import React from 'react'
import one from '../assets/img/landingpage/one.webp'
import two from '../assets/img/landingpage/two.webp'
import three from '../assets/img/landingpage/three.webp'
import lineup from '../assets/img/landingpage/lineup.png'
import tablet from '../assets/img/landingpage/tablet.webp'

const Features = () => {
  return (
    <div id="features" className="pl-4 lg:pl-40 mt-8 lg:mt-20 font-pjs section" style={{ animationDelay: '2s' }}>
        <h1 className='text-[#353F4F] text-[28px] font-bold tracking-wide mt-8 mb-2 text-center'>Personalized Dashboards</h1>
        <p className='text-[#757575] text-[16px] mb-8 text-center'> Simplifying life with access, security, perks, and updates.</p>
        <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/2 mt-10">
                <div className="flex gap-4 pr-8 pt-6">
                    <div>
                        <img src={one} alt='one'/>
                        <img src={lineup} alt='lineup' className='h-[50px] mx-auto mt-4'/>
                    </div>
                    <div>
                        <h1 className='text-[#353F4F] text-[20px] font-bold mb-4'>Business Empowerment Suite</h1>
                        <p className='text-[#757575] text-[14px] my-2'>Highlight key functionalities such as seamless booking experiences, customizable loading options, and comprehensive dealer and retailer support.</p>
                    </div>
                </div>
                <div className="flex gap-4 pr-8 pt-6">
                    <div>
                        <img src={two} alt='two'/>
                        <img src={lineup} alt='lineup' className='h-[50px] mx-auto mt-4'/>
                    </div>
                    <div>
                        <h1 className='text-[#353F4F] text-[20px] font-bold mb-4'>Profit Navigator</h1>
                        <p className='text-[#757575] text-[14px] my-2'>Provide intuitive interfaces for setting profit targets, monitoring revenue streams, and analyzing expense breakdowns.</p>
                    </div>
                </div>
                <div className="flex gap-4 pr-8 pt-6">
                    <div>
                        <img src={three} alt='three'/>
                    </div>
                    <div>
                        <h1 className='text-[#353F4F] text-[20px] font-bold mb-4'>Performance Pulse</h1>
                        <p className='text-[#757575] text-[14px] my-2'>Provide trend analysis and performance benchmarks to help users make data-driven decisions and capitalize on emerging opportunities in the market.</p>
                    </div>
                </div>
            </div>
            <img src={tablet} alt='tablet' className='w-100 lg:w-[50%] hover:transform hover:scale-105'/>
        </div>
    </div>
  )
}

export default Features